.ImagePreview{
    .preview-header{
        height: 1.38rem;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 0 5.6rem;
        box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.05);
        font-size: .32rem;
        color: #000000;
        font-weight: 500;
        .header-exit,.header-switch{
            display: flex;
            align-items: center;
        }
        .header-title{
            line-height: 1.38rem;
        }
        .header-exit{
            cursor: pointer;
            .exit-icon{
                .anticon{
                    margin-right: .21rem;
                }
            }
        }
        .header-switch{
            .switch-item{
                margin-left: .21rem;
                cursor: pointer;
                width: .68rem;
                height: .68rem;
                text-align: center;
                line-height: .68rem;
            }
            .switch-item-active{
                background: #ECECEC;
                border-radius: .05rem;
            }
        }
    }
    .preview-content{
        padding: 1.02rem 5.6rem 1.21rem;
        .content-title{
            font-size: .34rem;
            color: #000000;
            font-weight: 500;
            margin-bottom: .85rem;
        }
        .content-image-list-large{
            .image-list-item{
                margin-bottom: 1.42rem;
                position: relative;
            }
            .item-image{
                width: 100%;
                &:hover{
                    .enlarge{
                        display: block;
                    }
                }
                img{
                    width: 100%;
                }
            }
            .item-title{
                font-size: .2rem;
                color: #0C0C0C;
                margin-top: .32rem;
                margin-bottom: .06rem;
            }
            .item-subtitle{
                font-size: .14rem;
                color: #666666;
            }
            .mask{
                .sequence{
                    font-size: .3rem;
                    color: white;
                    width: 1.55rem;
                    height: .68rem;
                    border-radius: .84rem;
                    background-color: rgba(0,0,0,0.4);
                    text-align: center;
                    line-height: .68rem;
                    letter-spacing: .005rem;
                    position: absolute;
                    top: .28rem;
                    left: .36rem;
                }
                .enlarge{
                    width: .68rem;
                    height: .68rem;
                    text-align: center;
                    border-radius: .1rem;
                    position: absolute;
                    top: .28rem;
                    right: .25rem;
                    cursor: pointer;
                    display: none;
                }
            }
        }
        .content-image-list-small{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .image-list-item{
                position: relative;
                width: 3.3rem;
                height: 3.3rem;
                margin-bottom: .4rem;
                &:hover{
                    .enlarge{
                        display: block;
                    }
                }
                .item-image{
                    width: 100%;
                    height: 100%;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .mask{
                .sequence{
                    font-size: .18rem;
                    color: white;
                    width: .9rem;
                    height: .4rem;
                    border-radius: .49rem;
                    background-color: rgba(0,0,0,0.4);
                    text-align: center;
                    line-height: .4rem;
                    letter-spacing: .005rem;
                    position: absolute;
                    top: .1rem;
                    left: .09rem;
                }
                .enlarge{
                    width: .4rem !important;
                    height: .4rem !important;
                    text-align: center;
                    border-radius: .1rem;
                    position: absolute;
                    top: .1rem;
                    right: .15rem;
                    cursor: pointer;
                    display: none;
                }
            }
        }
    }
    .preview-footer{
        height: 1.1rem;
        background-color: white;
        box-shadow: 0px -6px 12px 0px rgba(0,0,0,0.05);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height .1s;
        .info-request{
            margin-right: .12rem;
            .ant-btn{
                width: 2.7rem;
                height: .54rem;
            }
        }
        .phone-contact{
            .ant-btn{
                width: 2.7rem;
                height: .54rem;
            }
        }
    }
    .preview-footer-fix{
        height: 1.1rem
    }
}

.ImagePreviewMobile{
    .preview-header{
        padding: 0 1rem;
        height: 4.0625rem;
        .header-exit{
            .exit-icon{
                padding-top: .18rem;
                .anticon{
                    font-size: .8125rem;
                }
            }
            .exit-text{
                font-size: .875rem;

            }
        }
        .header-title{
            line-height: 4.0625rem;
            .title-text{
                font-size: .875rem;
            }
        }
        .header-switch{
            .switch-item {
                width: 1.9375rem;
                height: 1.9375rem;
                line-height: 1.9375rem;
                margin-left: .625rem;
                padding-top: .25rem;
                &:last-child{
                    margin-left: 0;
                }
                .anticon{
                    font-size: 1rem;
                }
            }
        }
    }
    .preview-content{
        padding: 0;
        padding-top: 1.9375rem;
        .content-title{
            padding-left: 1rem;
            font-size: 1.25rem;
            line-height: 1.4375rem;
            margin-bottom: 1.9375rem;
        }
        .content-image-list-large{
            .image-list-item{
                margin-bottom: 1.9375rem;
                .mask{
                    .sequence{
                        width: 2.75rem;
                        height: 1.1875rem;
                        line-height: 1.1875rem;
                        left: 1.1875rem;
                        top: .5625rem;
                        font-size: .6875rem;
                    }
                    .enlarge{
                        width: 1.1875rem;
                        height: 1.1875rem;
                        line-height: 1.1875rem;
                        padding: 0;
                        border-radius: .1875rem;
                        right: .25rem;
                        top: .5rem;
                    }
                }
                .item-title{
                    font-size: .8125rem;
                    line-height: 1rem;
                }
            }
        }
        .content-image-list-small{
            padding: 0 1rem;
            .image-list-item{
                width: 10.25rem;
                height: 10.25rem;
                .item-image{
                    .mask{
                        .sequence{
                            width: 2.8125rem;
                            height: 1.25rem;
                            line-height: 1.25rem;
                            top: .3125rem;
                            left: .3125rem;
                            font-size: .5625rem;
                            border-radius: 1.5rem;
                        }
                        .enlarge{
                            width: 1.1875rem;
                            height: 1.1875rem;
                            line-height: 1.1875rem;
                            padding: 0;
                            border-radius: .1875rem;
                            right: .25rem;
                            top: .3125rem;
                        }
                    }
                }
            }
        }
    }
    .preview-footer{
        // height: 4.0625rem;
        .info-request,.phone-contact{
            .ant-btn{
                width: 10rem;
                height: 2.375rem;
                font-size: .6875rem;
            }
        }
        .info-request{
            .ant-btn{
                margin-right: .5rem;
            }
        }
    }
    .preview-footer-fix{
        height: 4.0625rem;
    }
}

.ant-dropdown{
    .ant-dropdown-menu{
        .ant-dropdown-menu-item{
            padding: .14rem .19rem;
            .tip{
                font-size: .16rem;
                .tip-title{
                    margin-right: .28rem;

                }
            }
        }
        .ant-dropdown-menu-item-active{
            color: #329DFF;
        }
    }
}

.contact-list-mobile{
    .ant-dropdown-menu{
        .ant-dropdown-menu-item{
            .tip{
                font-size: .6875rem;   
            }

        }
    }
}

.large-image-modal{
    width: 100% !important;
    padding: 0;
    top: 0;
    .ant-modal-content{
        padding: 1.38rem 2.3rem 0;
        height: 100vh;
        background-color: transparent;
    }
    .ant-modal-close{
        width: .4rem;
        height: .4rem;
        top: .52rem;
        right: .62rem;
        .CloseIcon{
            img{
                width: 100%;
            }
        }
    }
    .large-image{
        width: 100%;
        img{
            width: 100%;

        }
    }
}

.ant-modal-wrap{
    display: flex;
    align-items: center;
}

.large-image-modal-mobile{
    top: 0 !important;
    padding: 0 !important;
    max-width: 100vw !important;
    .ant-modal-content{
        height: auto;
        padding: 0 !important;
        .ant-modal-close{
            display: none;
        }
    }
}


