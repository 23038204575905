.About{
    .layout{
        width: 14.4rem;
        margin: 0 auto;

    }
    .About-banner{
        height: 5.4rem;
        background-color: #69DDFF;
        img{
            width: 100%;
            height: 100%;
        }
        margin-bottom: 1.2rem;
    }
    .About-content{
        .company-title,.company-business,.team-title,.about-map{
            text-align: center;
            .title{
                letter-spacing: .02rem;
                font-size: .4rem;
                font-weight: 500;
                color: #000;
            }
            // .subtitle{
            //     font-size: .18rem;
            //     color: #0496FF;
            //     font-weight: 600;
            // }
            .line{
                width: .35rem;
                height: .06rem;
                margin: .1rem auto 0; 
                background-color: #69DDFF;
                opacity: .5;
                border-radius: .34rem;
            }
        }
        .about-company{
            margin-bottom: 3rem;
            margin-top: 1.12rem;
            margin: 1.2rem auto 1.47rem;
            .company-content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1.2rem;
            }
            .content-image{
                width: 5.62rem;
                height: 5.03rem;
                margin-right: .46rem;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-mask-image: url('../images/img/title-bg-large1.png');
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                }
            }
            .content-info{
                width: 6.19rem;
                .info-title{
                    position: relative;
                    min-height: .44rem;
                    border-bottom: 0.01rem solid #EEEEEE;
                    .title{
                        font-size: .19rem;
                        letter-spacing: .0095rem;
                        font-weight: 600;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: .22rem;
                        z-index: 3;
                        line-height: .22rem;
                        color: #000;
                    }
                    img{
                        position: absolute;
                        width: .33rem;
                        height: .29rem;
                        z-index: 1;
                        top: 0;
                    }
                }
                .info-desc{
                    .desc-item{
                        // height: .72rem;
                        // line-height: .72rem;
                        padding: .14rem 0;
                        display: flex;
                        border-bottom: 0.01rem solid #EEEEEE;
                        font-size: .16rem;
                        line-height: .19rem;
                        color: #000000;
                        .item-left{
                            width: 2.3rem;
                        }
                        .item-right{
                            width: 4.7rem;
                        }
                    }
                }
            }
        }
        .business-info{
            text-align: center;
            margin: 0 auto;
            margin-top: 1rem;
            width: 12.58rem;
            .business-item{
                margin-bottom: .23rem;
                .item-title{
                    font-size: .28rem;
                    line-height: .31rem;
                    font-weight: 500;
                    color: #2F2F2F;
                    margin-bottom: .23rem;
                }
                .item-desc{
                    font-size: .24rem;
                    line-height: .36rem;
                    span{
                        color: #0496FF;
                    }
                }
            }
        }
        .about-team{
            margin-top: 3.5rem;
            .bg{
                img{
                    width: 100%;
                }
            }
            .team-content{
                // padding: .31rem 3.26rem .88rem;
                background-color: #84CCFF;
                .title,.subtitle{
                    color: white;
                }
                .team-info{
                    display: flex;
                    justify-content: center;
                    margin-top: .69rem;
                    .info-item{
                        width: 2.42rem;
                        margin-right: .31rem;
                        &:last-child{
                            margin-right: 0;
                        }
                        .item-image{
                            width: 100%;
                            height: 2.81rem;
                            margin-bottom: .14rem;
                            img{
                                width: 100%;
                                height: 100%;
                                border-radius: .1rem;
                            }
                        }
                        text-align: center;
                        color: white;
                        .item-name{
                            font-size: .17rem;
                            line-height: .2rem;
                            font-weight: bold;
                        }
                        .item-office{
                            font-size: .12rem;
                            line-height: .19rem;
                            font-weight: 600;
                            margin-bottom: .09rem;
                        }
                        .item-contact{
                            img{
                                height: .15rem;
                                margin-right: .17rem;
                                cursor: pointer;
                                &:nth-of-type(1){
                                    width: .09rem;
                                }
                                &:nth-of-type(2){
                                    width: .13rem;
                                }
                                &:nth-of-type(3){
                                    width: .12rem;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .about-map{
            margin-bottom: 1.5rem;
            .map-content{
                margin-top: .76rem;
                .text{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: .16rem;
                        height: .16rem;
                        margin-right: .07rem;
                    }
                    margin-top: .18rem;
                }
                .address{
                    font-size: .18rem;
                    color: #329DFF;
                }
            }
        }
        
    }
    .about-footer{
        background-color: #329DFF;
        padding-top: .9rem;
        padding-bottom: 1.13rem;
        .layout{
            width: 14.4rem;
            margin: 0 auto ;
            display: flex;
        }
        .footer-img{
            width: 2.09rem;
            height: .38rem;
            margin-right: 1.34rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .footer-content{
            color: white;
            width: calc(100% - 4.09rem);
            font-size: .16rem;
            .footer-title{
                margin-bottom: .07rem;
                font-weight: 600;
            }
            .footer-desc{
                display: flex;
                .subtitle{
                    margin-right: .21rem;
                }
                .desc{
                    line-height: .22rem;
                    font-size: .14rem;
                    .desc-text{
                        margin-bottom: .1rem;
                    }
                    .desc-link{
                        display: block;
                        color: white;
                    }
                    .img-desc{
                        display: flex;
                        align-items: center;
                        .img-instagram{
                            margin-right: .1rem;
                            width: .18rem;
                            height: .18rem;
                        }
                        .redBook{
                            display: flex;
                            align-items: center;
                            color: white;
                        }
                    }
                }
            }
            .footer-flex{
                display: flex;
                .flex-item{
                    // flex: 1;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.AboutMobile{
    .layout{
        width: 100%;
        .About-banner{
            height: 8.0625rem;
        }
    }
    .About-content{
        .item-title{
            .title{
                font-size: 1.625rem;
                line-height: 1.875rem;
                letter-spacing: .0625rem;
                margin-bottom: .1875rem;
            }
            .subtitle{
                font-size: .9375rem;
                line-height: 1.125rem;
            }
            .line{
                width: 2.3125rem;
                height: .375rem;
                border-radius: 2.1875rem;
                margin-top: .3125rem;
            }
            margin-bottom: 3.0625rem;
        }
        .about-company{
            margin-top: 3.5625rem;
            width: 100%;
            .company-content{
                width: 21.0625rem;
                margin: 0 auto 0;
                flex-direction: column-reverse;
                align-items: center;
                .content-image{
                    width: 21.0625rem;
                    height: 20.4375rem;
                }
                .content-info{
                    width: 21.0625rem;
                    .info-title{
                        height: 1.625rem;
                        .title{
                            font-size: 1.1875rem;
                            height: 1.375rem;
                            left: .8125rem;
                        }
                        img{
                            width: 1.875rem;
                            height: 1.8125rem;
                            top: -.5rem;
                        }
                    }
                    .info-desc{
                        .desc-item{
                            padding: 1.25rem .1875rem;
                            font-size: .8125rem;
                            line-height: 1rem;
                            .item-left{
                                width: 5.375rem;
                                margin-right: 2.375rem;
                            }
                            .item-right{
                                width: 12.375rem;
                            }
                        }
                    }
                }
            }
        }
        .company-business{
            .business-info{
                width: 21.0625rem;
                .business-item{
                    margin-bottom: 1.125rem;
                    .item-title{
                        font-size: 1.3125rem;
                        line-height: 1.5rem;
                        margin-bottom: 1.125rem;
                    }
                    .item-desc{
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                    }
                }
            }
        }
        .about-team{
            margin-top: 6.0625rem;
            .team-content{
                padding: 2.4375rem .875rem 3.125rem;
                .team-title{
                    .title{
                        font-size: 2rem;
                        line-height: 2.375rem;
                    }
                    .subtitle{
                        font-size: .9375rem;
                        line-height: 1.125rem;
                    }
                    .line{
                        width: 2.3125rem;
                        height: .375rem;
                        border-radius: 2.1875rem;
                    }
                }
                .team-info{
                    display: block;
                    margin: 1.2rem auto 0;
                    .info-item{
                        text-align: center;
                        width: 100%;
                        margin-bottom: 3.6875rem;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .item-image{
                            width: 21.625rem;
                            height: 25.0625rem;
                            margin-bottom: 1.3125rem;
                            img{
                                border-radius: .625rem;
                            }
                        }
                        .item-name{
                            font-size: 1.4375rem;
                            line-height: 1.6875rem;
                        }
                        .item-office{
                            font-size: .9375rem;
                            line-height: 1.5rem;
                            margin-bottom: .75rem;
                        }
                        .item-contact{
                            img{
                                margin-right: 1.5625rem;
                                &:first-child{
                                    width: .8125rem;
                                    height: 1.3125rem;
                                }
                                &:nth-child(2){
                                    width: 1.1875rem;
                                    height: 1.3125rem;
                                }
                                &:last-child{
                                    width: 1.0625rem;
                                    height: 1.3125rem;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .about-map{
            padding: 5.9375rem .875rem;
            .map-title{
                .title{
                    font-size: 2rem;
                    line-height: 2.375rem;
                }
                .subtitle{
                    font-size: .9375rem;
                    line-height: 1.125rem;
                }
                .line{
                    width: 2.3125rem;
                    height: .375rem;
                    border-radius: 2.1875rem;
                    margin-top: -.3rem;
                }
            }
            .map-content{
                margin-top: 3.5625rem;
                iframe{
                    width: 100% !important;
                    height: 6.9375rem !important;
                }
                .address{
                    font-size: .8125rem;
                    line-height: 1rem;
                    letter-spacing: .0625rem;
                }
            }
        }
        
    }
    .about-footer{
        height: 25.375rem;
        padding: 2.75rem 2.125rem;
        .layout{
            display: block;
            width: 100%;
            .footer-img{
                width: 8.9375rem;
                height: 1.4375rem;
            }
            .footer-content{
                .footer-title{
                    font-size: .875rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    margin-top: 1.875rem;
                    margin-bottom: .3125rem;
                }
                .footer-desc{
                    display: block;
                    font-size: .6875rem;
                    line-height: .8125rem;
                    margin-bottom: 2.375rem;
                    .subtitle{
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    .desc{
                        font-size: .6875rem;
                        line-height: .8125rem;
                        text-align: left;
                        .desc-text{
                            margin-bottom: .625rem;
                            cursor: pointer;
                            color: white;
                        }
                        .img-desc{
                            .img-instagram{
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: .375rem;
                            }
                            .wechatCode{
                                .tip-text{
                                    font-size: .6875rem !important;
                                }
                            }
                        }
                    }
                    .descMobile{
                        display: flex;
                    }
                }
                .footer-flex{
                    display: block;
                    .flex-item{
                        margin: 0;
                    }
                }
            }
        }
    }
}

