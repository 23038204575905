.Advisory{
    background-color: #F5F6F9;
    .advisory-write{
        .remark-title{
            text-align: center;
            position: relative;
            z-index: 1;
            background-color: #329DFF;
            color: white;
            height: 8.7rem;
            padding-top: 2.15rem;
            .title{
                font-size: .56rem;
                line-height: .66rem;
                font-weight: bold;
                margin-bottom: .48rem;
            }
            .subtitle{
                font-size: .28rem;
            }
            .line{
                width: 1.2rem;
                height: .1rem;
                border-radius: .16rem;
                background-color: #84CCFF;
                margin: 0 auto;
                margin-top: -0.14rem;
                
            }
        }
        .remark-content{
            color: #00A2FC;
            padding-bottom: 1.5rem;
            margin-top: -4rem;
            position: relative;
            z-index: 4;
            .content{
                margin: 0 auto;
                width: 14.4rem;
                background-color: white;
                padding: 1.03rem 0.96rem 1.11rem;
                border-radius: 1.2rem;
                .item{
                    margin-bottom: .5rem;
                    font-size: .18rem;
                    font-weight: bold;
                    .ant-input{
                        background-color: #F3FBFE;
                        border: none;
                        font-size: .18rem;
                        color: #09A5FC;
                    }
                    p{
                        margin-bottom: .22rem;
                    }
                    .textArea{
                        border-radius: .2rem;
                    }
                }
                .title{
                    font-size: .22rem;
                    line-height: .25rem;
                    font-weight: 400;
                }
                .name{
                    >p{
                        margin-bottom: .22rem;
                    }
                }
                .name,.connect{
                    .ant-input{
                        height: .45rem;
                        border-radius: .16rem;
                        padding-left: .18rem;
                    }
                }
                .connect{
                    display: flex;
                    justify-content: space-between;
                    .ant-input{
                        width: 6rem;
                    }
                }
                .room{
                    .select{
                        display: flex;
                    }
                    .ant-select{
                        height: .78rem;
                        margin-right: .48rem;
                        width: 6rem;
                        &:last-child{
                            width: 3.6rem;
                        }
                        .ant-select-selector{
                            border: .03rem solid white;
                            background-color: #F3FBFE;
                            color: #09A5FC;
                            font-size: .18rem;
                            height: 100%;
                            border-radius: .16rem;
                            padding-left: .18rem;
                            .ant-select-selection-item{
                                line-height: .78rem;
                            }
                        }
                        .ant-select-arrow{
                            right: .4rem;
                            top: 40%;
                            width: .3rem;
                            height: .3rem;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            .anticon{
                                color: #242331;
                                svg{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
                .desc{
                    .ant-input{
                        min-height: 1.5rem;
                        padding-left: .18rem;
                    }
                }
                .btn{
                    .ant-btn{
                        width: 2.28rem;
                        height: .8rem;
                        border-radius: .5rem;
                        font-size: .20rem;
                        background-color: #329DFF;
                        color: white;
                    }
                }
            }
        }
    }
    .advisory-content{
        padding-top: .77rem;
        padding-bottom: 1.74rem;
        .advisory-success{
            width: 14.4rem;
            height: 9rem;
            border-radius: 1.2rem;
            margin: 0 auto;
            background-color: white;
            padding-top: 1.9rem;
            .success-content{
                text-align: center;
                h3{
                    color: #329DFF;
                    letter-spacing: .01rem;
                    line-height: .35rem;
                    font-size: .3rem;
                    margin-bottom: 1.08rem;
                }
                .text{
                    width: 6.48rem;
                    p{
                        font-size: .16rem;
                        line-height: .19rem;
                        letter-spacing: .01rem;
                        color: #000000;
                    }
                    margin: 0 auto 3.1rem;
                }
                .ant-btn{
                    width: 3.28rem;
                    height: .5rem;
                    border-radius: .25rem;
                    background-color: #329DFF;
                    font-size: .18rem;
                }
            }
        }
    }
    .detail-footer{
        background-color: #329DFF;
        padding-top: .9rem;
        padding-bottom: 1.13rem;
        .layout{
            width: 14.4rem;
            margin: 0 auto ;
            display: flex;
        }
        .footer-img{
            width: 2.09rem;
            height: .38rem;
            margin-right: 1.34rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .footer-content{
            color: white;
            width: calc(100% - 4.09rem);
            font-size: .16rem;
            .footer-title{
                margin-bottom: .07rem;
                font-weight: 600;
            }
            .footer-desc{
                display: flex;
                .subtitle{
                    margin-right: .21rem;
                }
                .desc{
                    line-height: .22rem;
                    font-size: .14rem;
                    .desc-text{
                        margin-bottom: .1rem;
                    }
                    .desc-link{
                        display: block;
                        color: white;
                    }
                    .img-desc{
                        display: flex;
                        align-items: center;
                        .img-instagram{
                            margin-right: .1rem;
                            width: .18rem;
                            height: .18rem;
                        }
                        .redBook{
                            display: flex;
                            align-items: center;
                            color: white;
                        }
                    }
                }
            }
            .footer-flex{
                display: flex;
                .flex-item{
                    // flex: 1;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}


.AdvisoryMobile{
    .advisory-write{
        .remark-title{
            height: 26.3125rem;
            padding-top: 2.1875rem;
            .title{
                font-size: 2rem;
                line-height: 2.375rem;
            }
            .subtitle{
                font-size: .9375rem;
                line-height: 1.125rem;
            }
            .line{
                width: 3.75rem;
                height: .375rem;
                border-radius: 1.25rem;
                margin-top: -.4rem;
            }
        }
        .remark-content{
            margin-top: -15.625rem;
            .RemarkComponent{
                .content{
                    width: 22.25rem;
                    padding: 3.3125rem 1.25rem;
                    border-radius: 2.375rem;
                    .item{
                        font-size: .6875rem;
                        line-height: 1.0625rem;
                        margin-bottom: 1.6875rem;
                        .ant-input{
                            height: 2.5625rem;
                            border-radius: .625rem;
                            font-size: .6875rem;
                        }
                        p{
                            margin-bottom: .5rem;
                        }
                    }
                    .title{
                        font-size: .6875rem;
                    }
                    .name{
                        .ant-input{
                        }
                    }
                    .connect{
                        display: block;
                        .tel{
                            margin-bottom: 1.6875rem;
                        }
                        .ant-input{
                            width: 100%;
                        }
                    }
                    .room{
                        .ant-select{
                            height: 2.5625rem;
                            .ant-select-selector{
                                border-radius: .625rem;
                                .ant-select-selection-item{
                                    line-height: 2.5625rem;
                                    font-size: .6875rem;
                                }
                            }
                            .ant-select-arrow{
                                right: .6875rem;
                                width: 1rem;
                                height: 1rem;
                                top: 45%;
                                .anticon{
                                    svg{
                                        font-size: .6875rem;
                                    }
                                }
                            }
                            &:first-child{
                                width: 13.125rem;
                                margin-right: 1.125rem;
                            }
                            &:last-child{
                                width: 5.1875rem;
                            }
                        }
                    }
                    .desc{
                        .ant-input{
                            height: 9.75rem !important;
                        }
                    }
                    .btn{
                        .ant-btn{
                            width: 7.375rem;
                            height: 2.5625rem;
                            border-radius: 1.625rem;
                            font-size: .6875rem;
                        }
                    }
                }
            }
        }
    }
    .advisory-content{
        padding-bottom: 5.9375rem;
        padding-top: 2.125rem;
        .advisory-success{
            width: 22.25rem;
            margin: 0 auto;
            border-radius: 2.375rem;
            height: 37.5625rem;
            padding-top: 6.4375rem;
            .success-content{
                h3{
                    font-size: 1.25rem;
                    line-height: 1.4375rem;
                    letter-spacing: .0625rem;
                    margin-bottom: 4.5625rem;
                }
                .text{
                    width: 19.25rem;
                    margin-bottom: 12.625rem;
                    p{
                        font-size: .6875rem;
                        line-height: 1rem;
                        letter-spacing: .5px;
                    }
                }
                .ant-btn{
                    width: 13.6875rem;
                    height: 3rem;
                    border-radius: 1.5rem;
                    font-size: .75rem;
                }
            }
        }
    }
    .detail-footer{
        height: 25.375rem;
        padding: 2.75rem 2.125rem;
        .layout{
            display: block;
            width: 100%;
            .footer-img{
                width: 8.9375rem;
                height: 1.4375rem;
            }
            .footer-content{
                .footer-title{
                    font-size: .875rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    margin-top: 1.875rem;
                    margin-bottom: .3125rem;
                }
                .footer-desc{
                    display: block;
                    font-size: .6875rem;
                    line-height: .8125rem;
                    margin-bottom: 2.375rem;
                    .subtitle{
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    .desc{
                        font-size: .6875rem;
                        line-height: .8125rem;
                        text-align: left;
                        .desc-text{
                            margin-bottom: .625rem;
                            color: white;
                            cursor: pointer;
                        }
                        .img-desc{
                            .img-instagram{
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: .375rem;
                            }
                            .wechatCode{
                                .tip-text{
                                    font-size: .6875rem !important;
                                }
                            }
                        }
                    }
                    .descMobile{
                        display: flex;
                    }
                }
                .footer-flex{
                    display: block;
                    .flex-item{
                        margin: 0;
                    }
                }
            }
        }
    }
}

