.Detail{
    .Detail-content{
        width: 14.4rem;
        margin: 0 auto;
        .carousel-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 7rem;
            .ant-carousel{
                width: 100%;
                height: 100%;
                .slick-slider{
                    height: 100%;
                    // margin-right: .2rem;
                    >div{
                        height: 100%;
                    }
                }
                .slick-active{
                    // margin-right: .25rem;
                    // padding-left: .3rem;
                    // margin-left: .5rem;
                    position: relative;
                    .mask{
                        .enlarge{
                            width: .6rem;
                            height: .6rem;
                            // border-radius: .1rem;
                            cursor: pointer;
                            position: absolute;
                            right: .5rem;
                            top: .3rem;
                            display: none;
                            .anticon{
                                color: white;
                                font-size: .45rem;
                            }
                        }
                    }
                    &:hover{
                        .mask{
                            .enlarge{
                                display: block;
                            }
                        }
                    }
                }
                .slick-list{
                    height: 100%;
                }
                .slick-track{
                    height: 100%;
                }
                .carousel-slide{
                    text-align: center;
                    margin: 0 auto;
                    height: 100%;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 8.09rem;
                        object-fit: cover;
                    }
                }
                .slick-prev,.slick-next{
                    font-size: inherit;
                    color: currentColor;
                    width: .64rem;
                    height: .64rem;
                    border-radius: 50%;
                    z-index: 99;
                    margin-top: 0;
                    content: '>';
                    color: transparent;
                    background-color: rgba($color: #000000, $alpha: .15);
                    &:hover{
                        font-size: inherit;
                        color: transparent;
                    }
                    
                }
                .slick-next{
                    right: .24rem;
                    background: url('../images/icon/banner-arrow-right.png') no-repeat;
                    background-size: .64rem .64rem;
                }
                .slick-prev{
                    left: .24rem;
                    background: url('../images/icon/banner-arrow-left.png')  no-repeat ;
                    background-size: .64rem .64rem;
                }
                .slick-dots{
                    bottom: .59rem;
                    li{
                        margin-right: .09rem;
                        button{
                            width: .17rem;
                            height: .17rem;
                            border-radius: 50%;
                            opacity: 1;
                            background-color: rgba($color: #000000, $alpha: .2);
                        }
                    }
                    .slick-active{
                        padding: 0;
                        margin-right: 0;
                        button{
                            width: .17rem;
                            height: .17rem;
                            background-color: white;
                        }
                    }
                }
            }
        }
        .banner-slice{
            width: 100%;
            margin-top: -0.3rem;
            position: relative;
            height: .3rem;
            img{
                width: 100%;
                z-index: 2;
                position: absolute;
            }
        }
        .detail-title{
            // height: 2.23rem;
            background-color: #78B5FE;
            color: white;
            text-align: center;
            padding-top: .58rem;
            .property-title{
                p{
                    font-size: .32rem;
                    line-height: .38rem;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .property-advantage{
                width: 10rem;
                margin: .2rem auto .16rem;
                margin-top: .2rem;
                padding-bottom: .5rem;
                border-bottom: 1px solid rgba($color: #fff, $alpha: .2);
                ul{
                    display: flex;
                    // justify-content: space-between;
                    margin: 0 auto;
                    flex-wrap: wrap;
                    justify-content: center;
                    li{
                        display: flex;
                        align-items: center;
                        margin-bottom: .21rem;
                        margin-right: .53rem;
                    }
                    .hide{
                        display: none;
                    }
                    .advantage-icon{
                        width: .32rem;
                        height: .32rem;
                        
                        margin-right: .1rem;
                    }
                    .advantage-text{
                        color: rgba($color: #fff, $alpha: .65);
                        font-size: .23rem;
                    }
                }
                .advantage-line{
                    height: 1px;
                    width: 8.5rem;
                    margin: 0 auto;
                    background-color: white;
                    opacity: .2;
                }
            }
            .property-commute{
                display: flex;
                justify-content: space-around;
                width: 5.95rem;
                margin: 0 auto;
                .commute-item{
                    display: flex;
                    font-size: .2rem;
                    .commute-icon{
                        font-size: .2rem;
                        margin-right: .1rem;
                    }
                    .commute-text{
                        width: 1.5rem;
                        text-align: left;
                    }
                }
            }
        }
        .detail-images-list{
            overflow-x: hidden;
            margin-top: -.16rem;
        }
        .detail-image{
            height: 1.8rem;
            display: flex;
            animation: scroll 40s linear infinite; 
            flex-wrap: nowrap;
            animation-play-state: running;
            &:hover{
                animation-play-state: paused;
            }
            img{
                width: 1.8rem;
                height: 100%;
                object-fit: cover;
            }
            .image-item{
                position: relative;
                flex: 0 0 auto;
                cursor: pointer;
                .enlarge{
                    width: .3rem;
                    height: .3rem;
                    cursor: pointer;
                    text-align: center;
                    position: absolute;
                    right: .15rem;
                    top: .15rem;
                    display: none;
                    .anticon{
                        font-size: .45rem;
                        color: white;
                    }
                }
                &:hover{
                    .enlarge{
                        display: block;
                    }
                }
            }
            // .ImageSlide{
            //     .swiper{
            //         height: 100% !important;
            //     }
            //     .swiper-wrapper{
            //         .swiper-slide{
            //             width: 3.2rem !important;
            //             margin: 0 !important;
            //         }
            //     }
            // }
        }
        .detail-description{
            position: relative;
            padding: 1.7rem 1.61rem 1.24rem;
            background-color: #F9FCFF;
        }
        .detail-summary{
            // height: 3.2rem;
            // margin: 1.7rem auto 1.24rem;
            background-color: white;
            display: flex;
            padding: .48rem .36rem ;
            position: relative;
            .summary-img{
                width: 1.49rem;
                height: 1.49rem;
                background-color: #0496FF;
                text-align: center;
                line-height: 1.49rem;
                margin-right: .24rem;
                border-radius: .23rem;
                img{
                    width: 1rem;
                    height: 1rem;
                }
            }
            border-radius: .14rem;
            .summary-content{
                width: 8.14rem;
                .summary-title{
                    font-size: .26rem;
                    margin-bottom: .05rem;
                    color: #2F2F2F;
                    font-weight: 800;
                }
                .summary-desc{
                    font-size: .16rem;
                    line-height: .24rem;
                    
                }
                .desc-overflow{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
                .summary-icon{
                    width: .3rem;
                    height: .3rem;
                    background-color: rgba($color: #000000, $alpha: .05);
                    border-radius: .06rem;
                    text-align: center;
                    line-height: .3rem;
                    position: absolute;
                    bottom: .14rem;
                    right: .17rem;
                    cursor: pointer;
                    .anticon{
                        font-weight: bold;
                        color: #000;
                    }
                }
                .summary-icon-bottom{
                    transform: rotate(90deg);
                }
                .summary-icon-top{
                    transform: rotate(-90deg);
                }
            }
        }
        .left{
            position: absolute;
            left: .7rem;
            bottom: 1rem;
            width: .9rem;
            height: .74rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .right{
            width: 1.12rem;
            height: 1.16rem;
            position: absolute;
            right: .9rem;
            top: 1rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .detail-intelligence{
            // height: 13.24rem;
            padding: .79rem 1.99rem .98rem;
            background-color: #84CCFF;
            position: relative;
            margin-bottom: 1.44rem;
            .background-left{
                width: 14.4rem;
                height: auto;
                position: absolute;
                left: .6rem;
                top: 1.86rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .background-right{
                width: 4.78rem;
                height: 2.72rem;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 3;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            // background: url('../images/img/intelligence-background.png') no-repeat 14.4rem 100%;
            .intelligence-content{
                width: 10.41rem;
                margin: 0 auto;
                position: relative;
                z-index: 5;
                .ant-tabs{
                    align-items: center;
                    color: white;
                    .ant-tabs-nav{
                        // width: 10.8rem;
                        margin-bottom: 0;
                        position: relative;
                        z-index: 2;
                        &::before{
                            content: "";
                            display: none;
                        }
                        color: #329DFF;
                        .ant-tabs-nav-wrap{
                            .ant-tabs-nav-list{
                                justify-content: center;
                                align-items: end;
                                .ant-tabs-tab{
                                    background-color: white;
                                    font-size: .2rem;
                                    width: 2.16rem;
                                    height: .74rem;
                                    justify-content: center;
                                    border: 2px solid #329DFF;
                                    margin-left: 0;
                                    border-left: none;
                                    padding: 0;
                                    line-height: .74rem;
                                    // &:nth-of-type(n+1) {
                                    //     border-bottom-left-radius: 0;
                                    //     border-bottom-right-radius: 0;
                                    // }
                                    &:first-child{
                                        border-left: 2px solid #329DFF;
                                    }
                                    .ant-tabs-tab-btn{
                                        width: 100%;
                                        height: .74rem;
                                        line-height: .74rem;
                                        text-align: center;
                                    }
                                }
                                .ant-tabs-tab-active{
                                    height: .89rem;
                                    background-color: #329DFF;
                                    border: none !important;
                                    border-radius: 18px 18px 18px 18px;
                                    .ant-tabs-tab-btn{
                                        color: white;
                                        line-height: .89rem;
                                    }
                                    &:first-child{
                                        border-bottom-right-radius: 0;
                                    }
                                    &:nth-child(2){
                                        border-bottom-left-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                    &:nth-child(3){
                                        border-bottom-left-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                    &:nth-child(4){
                                        border-bottom-left-radius: 0;
                                        border-bottom-right-radius: 0;
                                    }
                                    &:nth-child(5){
                                        border-bottom-left-radius: 0;
                                    }
                                }
                                .ant-tabs-ink-bar{
                                    display: none;
                                }
                            }
                        }
                    }
                    .ant-tabs-content-holder{
                        width: 100%;
                        // height: 9rem;
                        background-color: white;
                        box-shadow: 0px 50px 100px 0px rgba(50,157,255,0.1);
                        margin-top: -.3rem;
                        border-radius: .76rem;
                        color: #000000;
                    }
                }
                .tabs-item{
                    font-size: .16rem;
                }
                .detail-tabs{
                    margin: 0 auto;
                    .detail-tabs-nav{
                        position: relative;
                        z-index: 2;
                        .detail-tabs-nav-list{
                            display: flex;
                            justify-content: center;
                            .nav-list-li{
                                background-color: white;
                                font-size: .18rem;
                                width: 1.56rem;
                                height: .53rem;
                                justify-content: center;
                                border: 2px solid #329DFF;
                                margin-left: 0;
                                border-left: none;
                                border-right: none;
                                padding: 0;
                                line-height: .53rem;
                                color: #329DFF;
                                text-align: center;
                                margin-top: .11rem;
                                cursor: pointer;
                                &:first-child{
                                    border-left: 2px solid #329DFF;
                                }
                                &:last-child{
                                    border-right: 2px solid #329DFF;
                                }
                            }
                            .first-li{
                                border-radius: .17rem 0 0 .17rem;

                            }
                            .last-li{
                                border-radius: 0 .17rem .17rem 0;

                            }
                            .nav-list-li-active{
                                height: .64rem;
                                line-height: .78rem;
                                background-color: #329DFF;
                                border: none !important;
                                border-radius: .15rem;
                                color: white;
                                margin-top: 0;
                                .ant-tabs-tab-btn{
                                    color: white;
                                    line-height: .89rem;
                                }
                                &:first-child{
                                    border-bottom-right-radius: 0;
                                }
                                &:nth-child(2){
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                }
                                &:nth-child(3){
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                }
                                &:nth-child(4){
                                    border-bottom-left-radius: 0;
                                }
                                &:nth-child(5){
                                    border-bottom-left-radius: 0;
                                }
                            }
                        }
                    }
                    .detail-tabs-wrapper{
                        background-color: white;
                        box-shadow: 0px 50px 100px 0px rgba(50,157,255,0.1);
                        margin-top: -.3rem;
                        border-radius: .55rem;
                        color: #000000;
                    }
                }
                .BasicData{
                    padding: 1.25rem 1.23rem .4rem;
                    .basic-item{
                        display: flex;
                        padding-bottom: .19rem;
                        margin-bottom: .18rem;
                        border-bottom: 1px solid #EEEEEE;
                        color: #000000;
                        line-height: .19rem;
                        .title{
                            width: 1.5rem;
                            margin-right: .6rem;
                        }
                        .content{
                            width: 5.91rem;
                            overflow-x: hidden;
                        }
                    }
                    .tenant{
                        .content{
                            // overflow-x: scroll;
                            // ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6{
                            //     font-size: .16rem;
                            // }
                        }
                    }
                }
                .BasicMap{
                    padding: 1.25rem 1.23rem .4rem;
                    .map-address{
                        margin-top: .3rem;
                        width: 7.95rem;
                        height: .6rem;
                        background-color: #F4F4F4;
                        border-radius: .15rem;
                        line-height: .6rem;
                        padding-left: .25rem;
                        display: flex;
                        align-items: center;
                        .address-icon{
                            width: .5rem;
                            height: .5rem;
                            // background-color:#0496FF;
                            // margin-right: .19rem;
                            text-align: center;
                            img{
                                width: .25rem;
                                height: .25rem;
                                object-fit: cover;
                                margin-top: -.15rem;
                            }
                        }
                    }
                }
                .Around{
                    padding: .82rem .68rem .49rem;
                    position: relative;
                    .large-image-box{
                        overflow-x: hidden;
                    }
                    .large-image-list{
                        display: flex;
                        flex-wrap: nowrap;
                        justify-content: left;
                        transition: transform 0.5s ease;
                        width: 9.04rem;
                        .item-box{
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: left;
                            flex-shrink: 0;
                            width: 9.04rem;
                        }
                        .image-item{
                            margin-right: .17rem;
                            margin-bottom: .33rem;
                            position: relative;
                            cursor: pointer;
                            &:nth-of-type(3n) {
                                margin-right: 0;
                            }
                            img{
                                border-radius: .1rem;
                                object-fit: cover;
                                width: 2.9rem;
                                height: 2.12rem;
                            }
                            .enlarge{
                                width: .6rem;
                                height: .6rem;
                                text-align: center;
                                cursor: pointer;
                                position: absolute;
                                right: .2rem;
                                top: .2rem;
                                display: none;
                                .anticon{
                                    color: white;
                                    font-size: .45rem;
                                }
                            }
                            &:hover{
                                .enlarge{
                                    display: block;
                                }
                            }
                            .image-desc{
                                font-size: .16rem;
                                width: 2.9rem;
                                color: #000;
                                line-height: .19rem;
                                letter-spacing: .01rem;
                                margin-top: .12rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .small-image-box{

                        overflow: hidden;
                    }
                    .small-image-list{
                        display: flex;
                        flex-wrap: nowrap;
                        // padding-left: .06rem;
                        margin-top: .15rem;
                        transition: transform 0.5s ease;
                        justify-content: center;
                        .small-image-item{
                            // width: 1.16rem;
                            // height: .64rem;
                            // margin-right: .14rem;
                            flex: 0 0 auto;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: .1rem;
                            }
                            .page-item{
                                width: .16rem;
                                height: .16rem;
                                margin-right: .08rem;
                                border-radius: 50%;
                                cursor: pointer;
                                background-color: rgba($color: #000000, $alpha: .1);
                            }
                            .page-item-active{
                                background-color: #329DFF;
                            }
                            .noPage-item{
                                display: none;
                            }
                        }
                    }
                    .image-btn{
                        .btn{
                            position: absolute;
                            width: .40rem;
                            height: .34rem;
                            border-radius: .3rem;
                            text-align: center;
                            line-height: .34rem;
                            cursor: pointer;
                            .anticon{
                                font-size: .14rem;
                                color: #3C4563;
                                font-weight: 600;
                            }
                        }
                        .prev-btn{
                            background-color: #E0E3EB;
                            left: .18rem;
                            bottom: .98rem;
                        }
                        .next-btn{
                            right: .18rem;
                            bottom: .98rem;
                            background-color: #E0E3EB;
                        }
                        .bgBlue{
                            background-color: #0496FF;
                            .anticon{
                                color: white;
                            }
                        }
                    }
                }
                .DeviceInformation{
                    padding: 1.25rem 1.23rem .4rem;
                }
                .SpecificData{
                    padding: 1.25rem 1.23rem .4rem;
                }
                .EquipmentAndDetail{
                    padding: 1.25rem 1.87rem .58rem;
                    .icon-list{
                        display: flex;
                        flex-wrap: wrap;
                        .list-item{
                            text-align: center;
                            width: .78rem;
                            margin-right: .2rem;
                            margin-bottom: .06rem;
                            &:nth-child(7n) {
                                margin-right: 0;
                            }
                            .icon{
                                width: .6rem;
                                height: .6rem;
                                margin-bottom: .06rem;
                            }
                            .text{
                                font-size: .12rem;
                            }
                        }
                        .list-item-active{
                            .text{
                                color: #1890FF;
                            }
                        }
                    }
                    .text-list{
                        margin-top: .51rem;
                        .list-item{
                            display: flex;
                            padding-bottom: .19rem;
                            border-bottom: 1px solid #EEEEEE;
                            margin-bottom: .19rem;
                            .item-title,.item-data{
                                font-size: .16rem;
                                color: #000000;
                                line-height: .22rem;
                            }
                            .item-title{
                                width: 1.01rem;
                                margin-right: .72rem;
                            }
                            .item-data{
                                width: 4.93rem;
                            }
                        }
                    }
                }
            }
        }
        .detail-room{
            // height: 14.4rem;
            padding-top: 2rem;
            padding-bottom: 1.02rem;
            position: relative;
            .room-title{
                text-align: center;
                .title{
                    width: 10rem;
                    letter-spacing: .02rem;
                    font-size: .4rem;
                    line-height: .47rem;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 0 auto;
                    color: #000;
                }
                .subtitle{
                    font-size: .18rem;
                    color: #0496FF;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                }
                .line{
                    width: .59rem;
                    height: .09rem;
                    margin: -.1rem auto 0; 
                    background-color: #69DDFF;
                    opacity: .5;
                    border-radius: .05rem;
                    z-index: 1;
                }
            }
            .room-content{
                width: 15.45rem;
                margin: 1.2rem auto 0;
                display: flex;
                justify-content: space-between;
                .room-info{
                    .room-desc{
                        border-bottom: 2px solid #F0F3FD;
                        width: 5.46rem;
                        padding-bottom: .1rem;
                        .room-floor{
                            width: .48rem;
                            height: .46rem;
                            position: relative;
                            text-align: center;
                            line-height: .46rem;
                            span{
                                color: white;
                                display: inline-block;
                                width: .48rem;
                                text-align: center;
                                position: absolute;
                                z-index: 3;
                                left: 0;
                                font-size: .2rem;
                                font-weight: 500;
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                z-index: 2;
                            }
                        }
                        .room-num{
                            font-size: .4rem;
                            font-weight: 800;
                        }
                        .room-feature{
                            font-size: .23rem;
                            line-height: .32rem;
                            color: #626687;
                            opacity: .75;
                            .areaAndSental{
                                display: flex;
                                color: #333333;
                                margin-bottom: .07rem;
                                .area{
                                    margin-right: .3rem;
                                }
                            }
                            .desc{
                                color: #545581;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                    .room-image{
                        width: 4.48rem;
                        height: 3.28rem;
                        margin-top: .07rem;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .room-img-position{
                    position: relative;
                    width: 8.08rem;
                }
                .room-img-box{
                    width: 5.5rem;
                    height: 5.18rem;
                    margin: 0 auto;
                    overflow-x: hidden;
                    border-radius: .2rem;

                }
                .room-img{
                    display: flex;
                    width: 5.5rem;
                    height: 5.18rem;
                    transition: transform 0.5s ease;
                    position: relative;
                    border-radius: .2rem;
                    .img-content{
                        position: relative;
                        margin: 0 auto;
                        flex: 0 0 auto;
                        width: 5.5rem;
                        height: 5.18rem;
                        img{
                            width: 100%;
                            height: 100%;
                            // object-fit: cover;
                            // -webkit-mask-image: url('../images/img/title-bg-large2.png');
                            // -webkit-mask-repeat: no-repeat;
                            // -webkit-mask-size: 100% 100%;
                        }
                    }
                    .mask{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba($color: #000000, $alpha: .3);
                        object-fit: cover;
                        -webkit-mask-image: url('../images/img/title-bg-large2.png');
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-size: 100% 100%;
                        text-align: center;
                        color: white;
                        p{
                            font-size: .84rem;
                            font-weight: 600;
                            line-height: 5.4rem;
                        }
                    }
                }
                .img-btn-prev{
                    width: .6rem;
                    height: .6rem;
                    text-align: center;
                    line-height: .6rem;
                    border-radius: 50%;
                    box-shadow: 0 .07rem .12rem rgba($color: #000000, $alpha: .05);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-top: .05rem;
                    cursor: pointer;
                    .anticon{
                        font-size: .24rem;
                    }
                }
                .img-btn-next{
                    width: .6rem;
                    height: .6rem;
                    text-align: center;
                    line-height: .6rem;
                    border-radius: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    right: 0;
                    top: 50%;
                    padding-top: .05rem;
                    cursor: pointer;
                    box-shadow: 0 .07rem .12rem rgba($color: #000000, $alpha: .05) ;
                    .anticon{
                        font-size: .24rem;
                    }
                }
            }
            .room-name{
                overflow-x: hidden;
                width: 14.4rem;
                margin: .48rem auto;
                .room-list{
                    display: flex;
                    .room-list-item{
                        width: 1.2rem;
                        height: .52rem;
                        text-align: center;
                        line-height: .52rem;
                        background-color: #F6F6F6;
                        color: #999999;
                        margin-right: .2rem;
                        flex: 0 0 auto;
                        border-radius: .24rem;
                        margin-bottom: .34rem;
                        cursor: pointer;
                    }
                    .room-list-item-active{
                        background-color: #329DFF;
                        color: white;
                    }
                }
                .show-list{
                    flex-wrap: wrap;
                }
                .room-showAll-btn{
                    width: .55rem;
                    height: .51rem;
                    border-radius: .3rem;
                    text-align: center;
                    margin: 0 auto 0;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .room-bg{
                width: 1.55rem;
                height: 1.75rem;
                background: #99e5fb;
                opacity: 0.7;
                transform: rotate(90deg);
                filter: blur(0.5rem);
                position: absolute;
                top: 0;
                left: 1.55rem;
                border-radius: 50%;
            }
        }
        .detail-Panorama{
            padding-bottom: 2rem;
            background-color: #F9FCFF;
            padding-top: 1.25rem;
            // padding: 1.9rem 5.5rem 1.5rem;
            .room-title{
                text-align: center;
                .title{
                    letter-spacing: .02rem;
                    font-size: .4rem;
                    font-weight: 500;
                    color: #000;
                }
                .subtitle{
                    font-size: .18rem;
                    color: #0496FF;
                    font-weight: 600;
                    position: relative;
                    z-index: 2;
                }
                .line{
                    width: .35rem;
                    height: .06rem;
                    margin: -.1rem auto 0; 
                    background-color: #69DDFF;
                    opacity: .5;
                    border-radius: .05rem;
                    z-index: 1;
                }
            }
            .panorama-image{
                width: 14.4rem;
                height: 11rem;
                margin: .8rem auto 0;
                border: .14rem solid #0496FF;
                border-radius: .6rem;
            }
        }
        .detail-remark{
            position: relative;
        }
        .detail-remark-title{
            .remark-bg{
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .remark-title{
                background-color: #84CCFF;
                height: 4.86rem;
                text-align: center;
                color: white;
                padding-top: 1.08rem;
                .title{
                    font-size: .4rem;
                }
                .subtitle{
                    font-size: .18rem;
                    position: relative;
                    z-index: 2;
                }
                .line{
                    width: .67rem;
                    height: .06rem;
                    background: #B4EEFF;
                    border-radius: .34rem;
                    opacity: 0.5;
                    margin: -.1rem auto 0;
                }
            }
        }
        .detail-remark-content{
            background-color: #F5FAFF;
            min-height: 5.51rem;
            .content{
                margin: 0 auto;
                width: 8.1rem;
                background-color: white;
                padding: .53rem 0.59rem .59rem;
                border-radius: .67rem;
                position: absolute;
                left: 0;
                right: 0;
                top: 3.4rem;
                .item{
                    margin-bottom: .16rem;
                    font-size: .14rem;
                    color: #00A2FC;
                    font-weight: bold;
                    .ant-input{
                        background-color: #F3FBFE;
                        border: none;
                        font-size: .14rem;
                        color: #09A5FC;
                    }
                    p{
                        margin-bottom: .06rem;
                    }
                    .textArea{
                        border-radius: .2rem;
                    }
                }
                .title{
                    font-size: .18rem;
                    line-height: .27rem;
                    font-weight: 400;
                }
                .name{
                    >p{
                        margin-bottom: .06rem;
                    }
                }
                .name,.connect{
                    .ant-input{
                        height: .45rem;
                        border-radius: .16rem;
                        padding-left: .18rem;
                    }
                }
                .connect{
                    display: flex;
                    justify-content: space-between;
                    .ant-input{
                        width: 3.37rem;
                        padding-left: .18rem;
                    }
                }
                .room{
                    .ant-select{
                        height: .45rem;
                        margin-right: .27rem;
                        width: 3.37rem;
                        &:last-child{
                            width: 2.02rem;
                        }
                        .ant-select-selector{
                            border: .03rem solid white;
                            background-color: #F3FBFE;
                            color: #09A5FC;
                            font-size: .14rem;
                            height: 100%;
                            border-radius: .16rem;
                            padding-left: .18rem;
                            .ant-select-selection-item{
                                line-height: .4rem;
                            }
                        }
                        .ant-select-arrow{
                            right: .2rem;
                            top: 40%;
                            width: .2rem;
                            height: .2rem;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            .anticon{
                                color: #242331;
                                svg{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                .desc{
                    .ant-input{
                        min-height: .84rem;
                        padding-left: .18rem;
                    }
                }
                .btn{
                    .ant-btn{
                        width: 1.28rem;
                        height: .47rem;
                        border-radius: .28rem;
                        font-size: .12rem;
                        background-color: #329DFF;
                        color: white;
                        margin-top: .24rem;
                    }
                }
            }
        }
        
    }
    .detail-footer{
        background-color: #329DFF;
        padding-top: .9rem;
        padding-bottom: 1.13rem;
        .layout{
            width: 14.4rem;
            margin: 0 auto ;
            display: flex;
        }
        .footer-img{
            width: 2.09rem;
            height: .38rem;
            margin-right: 1.34rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .footer-content{
            color: white;
            width: calc(100% - 4.09rem);
            font-size: .16rem;
            .footer-title{
                margin-bottom: .07rem;
                font-weight: 600;
            }
            .footer-desc{
                display: flex;
                .subtitle{
                    margin-right: .21rem;
                }
                .desc{
                    line-height: .22rem;
                    font-size: .14rem;
                    .desc-text{
                        margin-bottom: .1rem;
                    }
                    .desc-link{
                        display: block;
                        color: white;
                    }
                    .img-desc{
                        display: flex;
                        align-items: center;
                        .img-instagram{
                            margin-right: .1rem;
                            width: .18rem;
                            height: .18rem;
                        }
                        .redBook{
                            display: flex;
                            align-items: center;
                            color: white;
                        }
                    }
                }
            }
            .footer-flex{
                display: flex;
                .flex-item{
                    // flex: 1;
                    margin-right: 1.5rem;
                }
            }
        }
    }
    .detail-beaver{
        width: 2.65rem;
        height: 1.8rem;
        position: fixed;
        right: .75rem;
        bottom: .47rem;
        z-index: 99;
        cursor: pointer;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.DetailMobile{
    .Detail-content{
        width: 100%;
        .carousel-container{
            .slick-slider{
                .slick-arrow{
                    width: 2.0625rem;
                }
                .slick-prev{
                    left: .01rem;
                }
                .slick-next{
                    right: .01rem;
                }
            }
        }
        .detail-title{
            // height: 12.5rem;
            padding-top: 2.25rem;
            margin-top: -.3rem;
            padding-bottom: 2rem;
            .property-title{
                margin-bottom: 1.5rem;
                p{
                    font-size: 1.375rem;
                    line-height: 1.5625rem;
                    font-weight: 500;
                    letter-spacing: .0625rem;
                }
            }
            .property-advantage{
                width: 21.0625rem;
                padding-bottom: 1.125rem;
                border-bottom: none;
                ul{
                    li{
                        margin-bottom: .5rem;
                        margin-right: 1rem;
                    }
                    .advantage-icon{
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: .625rem;
                    }
                    .advantage-text{
                        font-size: .6875rem;
                        font-weight: 500;
                    }
                }
                .advantage-line{
                    width: 16rem;
                    margin-top: 1.2rem;
                }
            }
        }
        .detail-images-list{
            height: 4.6875rem;
            margin-top: 0;
            .detail-image{
                height: auto;
                .image-item{
                    img{
                        width: 4.6875rem;
                        height: 4.6875rem;
                    }
                }
            }
        }
        .detail-description{
            .detail-summary{
                width: 19.375rem;
                padding: .875rem 1.625rem 1.875rem .625rem;
                border-radius: .1875rem;
                .summary-img{
                    width: 2.5625rem;
                    height: 2.5625rem;
                    margin-right: .4375rem;
                    line-height: 2.25rem;
                    img{
                        width: 1.375rem;
                        height: 1.375rem;
                    }
                }
                .summary-content{
                    width: 14.125rem;
                    .summary-title{
                        font-size: .8125rem;
                        line-height: .9375rem;
                    }
                    .summary-desc{
                        font-size: .6875rem;
                        line-height: 1.0625rem;
                    }
                    .summary-icon{
                        width: .8125rem;
                        height: .8125rem;
                        bottom: .25rem;
                        right: .25rem;
                        .anticon{
                            font-size: .6875rem;
                        }
                    }
                }
            }
            .left{
                left: 1rem;
                width: 1.1875rem;
                height: 1rem;
            }
            .right{
                width: 1.9375rem;
                height: 2rem;
                right: .8rem;
            }
        }
        .detail-intelligence{
            padding: 2.6875rem 0 4.5625rem;
            .intelligence-content{
                width: 100%;
                .detail-tabs{
                    .detail-tabs-nav{
                        width: 21.0625rem;
                        margin: 0 auto;
                        .detail-tabs-nav-list{
                            align-items: end;
                            .nav-list-li{
                                width: 5rem;
                                height: 1.6875rem;
                                line-height: 1.6875rem;
                                border: .0625rem solid #329DFF;
                                font-size: .875rem;
                                &:first-child{
                                    border-left: .0625rem solid #329DFF;
                                }
                                &:last-child{
                                    border-right: .0625rem solid #329DFF;
                                }
                                border-left: none;
                                border-right: none;
                            }
                            .nav-list-li-active{
                                min-width: 4.25rem;
                                height: 2rem;
                                line-height: 2rem;
                                border-radius: .375rem;
                            }
                        }
                    }
                    .detail-tabs-wrapper{
                        margin-top: -0.875rem;
                        .BasicData{
                            padding: 2.5rem 1.1875rem 6.5625rem;
                            .basic-item{
                                display: block;
                                margin-bottom: 1.1875rem;
                                padding-bottom: .75rem;
                                font-size: .8125rem;
                                line-height: 1rem;
                                .title{
                                    width: 100%;
                                    margin-bottom: .75rem;
                                    font-weight: 500;
                                }
                                .content{
                                    width: 21.0625rem;
                                    overflow: hidden;
                                    font-size: .8125rem;
                                }
                            }
                            .tenant{
                                .content{
                                    // ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6{
                                    //     font-size: .8125rem;
                                    // }
                                }
                            }
                        }
                        .BasicMap{
                            height: 19.25rem;
                            padding: 2.25rem .875rem 1.75rem;
                            .map-address{
                                width: 100%;
                                height: 1.8125rem;
                                padding-left: .625rem;
                                border-radius: .3125rem;
                                margin-top: 1.0625rem;
                                .address-text{
                                    font-size: .6875rem;
                                }
                                .address-icon{
                                    width: .8125rem;
                                    height: .8125rem;
                                    margin-right: .4375rem;
                                    img{
                                        width: 100%;
                                        height: 100%;
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                        .Around{
                            height: 18.375rem;
                            padding: 2rem .875rem 0;
                            .large-image-box{
                                width: 21.625rem;
                                margin-bottom: 1rem;
                                .large-image-list{
                                    width: 21.625rem;
                                    .item-box{
                                        width: 21.625rem;
                                    }
                                    .image-item{
                                        img{
                                            border-radius: .1875rem;
                                            width: 6.9375rem;
                                            height: 5.0625rem;
                                        }
                                        .mask{
                                            .enlarge{
                                                width: 1.5rem;
                                                height: 1.5rem;
                                                border-radius: .3125rem;
                                                .anticon{
                                                    font-size: .8125rem;
                                                }
                                            }
                                        }
                                        .image-desc{
                                            width: 6.9375rem;
                                            font-size: .8125rem;
                                            line-height: 1.1875rem;
                                            height: 1.1875rem;
                                        }
                                    }
                                }
                            }
                            .small-image-box{
                                width: 17.5rem;
                                margin: 0 auto;
                                .small-image-list{
                                    margin-top: 0;
                                    .small-image-item{
                                        .page-item{
                                            width: 1rem;
                                            height: 1rem;
                                            margin-right: .75rem;
                                        }
                                    }
                                }
                            }
                            .btn{
                                width: 1.625rem;
                                height: 1.375rem;
                                border-radius: .875rem;
                                padding-top: .35rem;
                                .anticon{
                                    font-size: .6875rem;
                                }
                            }
                            .prev-btn{
                                left: .875rem;
                                bottom: 2.3125rem;
                            }
                            .next-btn{
                                right: .875rem;
                                bottom: 2.3125rem;
                            }
                        }
                        .EquipmentAndDetail{
                            padding: 2.5rem 1.1875rem 6.5625rem;
                            .icon-list{
                                flex-wrap: wrap;
                                .list-item{
                                    width: 4.375rem;
                                    margin-bottom: 1rem;
                                    margin-right: 1.125rem;
                                    .icon{
                                        width: 3.375rem;
                                        height: 3.375rem;
                                    }
                                    .text{
                                        font-size: .6875rem;
                                        line-height: .8125rem;
                                    }
                                    &:nth-child(4n){
                                        margin-right: 0;
                                    }
                                }
                            }
                            .text-list{
                                .list-item{
                                    display: block;
                                    margin-bottom: 1.1875rem;
                                    padding-bottom: .75rem;
                                    font-size: .8125rem;
                                    line-height: 1rem;
                                    .item-title{
                                        width: 100%;
                                        margin-bottom: .75rem;
                                        font-weight: 500;
                                        line-height: 1rem;
                                        font-size: .8125rem;
                                    }
                                    .item-data{
                                        width: 21.0625rem;
                                        overflow: hidden;
                                        font-size: .8125rem;
                                        line-height: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .detail-room{
            height: auto;
            padding-bottom: 0;

            .room-title{
                .title{
                    font-size: 2rem;
                    line-height: 2.375rem;
                    width: 21.0625rem;
                }
                .subtitle{
                    font-size: .9375rem;
                    line-height: 1.125rem;
                    margin-top: .3125rem;
                }
                .line{
                    width: 2.3125rem;
                    height: .375rem;
                    border-radius: 2.1875rem;
                    margin-top: -.4rem;
                }
            }
            .room-content{
                display: block;
                width: 20.75rem;
                .room-info{
                    .room-desc{
                        width: 100%;
                        padding-bottom: 1.8125rem;
                        .room-floor{
                            width: 2.25rem;
                            height: 2.125rem;
                            span{
                                font-size: .9375rem;
                                width: auto;
                                line-height: 2.125rem;
                                left: .6rem;
                                top: 0;
                                bottom: 0;
                            }
                        }
                        .room-num{
                            font-size: 1.8125rem;
                            line-height: 2.6875rem;
                            margin-bottom: .625rem;
                        }
                        .room-feature{
                            font-size: .9375rem;
                            line-height: 1.625rem;
                        }
                    }
                    .room-image{
                        width: 20.75rem;
                        height: 15.1875rem;
                        margin: .875rem auto 0;
                    }
                }
                .room-img-position{
                    width: 20.375rem;
                    .room-img-box{
                        width: 100%;
                        height: 18.3125rem;
                        .room-img{
                            width: 20.375rem;
                            height: 18.3125rem;
                            .img-content{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .room-btn{
                            // display: none;
                        }
                    }
                }
            }
            .room-name{
                width: 20.75rem;
                margin-top: 1.25rem;
                .room-list{
                    .room-list-item{
                        padding: .375rem .9375rem;
                        width: auto;
                        font-size: .6875rem;
                        line-height: .8125rem;
                        height: auto;
                        border-radius: .8125rem;
                        margin: 0;
                        margin-right: .625rem;
                    }
                }
                .show-list{
                    .room-list-item{
                        margin-bottom: .4rem;

                    }
                }
                .room-showAll-btn{
                    width: 1.9375rem;
                    height: 1.6875rem;
                    line-height: 1.5625rem;
                    border-radius: 1rem;
                    margin-top: 2.1875rem;
                    .anticon{
                        font-size: .6875rem;
                    }
                }
            }
        }
        .detail-Panorama{
            padding: 0;
            margin: 6.5625rem auto;
            background-color: white;
            .room-title{
                .title{
                    font-size: 2rem;
                    line-height: 2.375rem;
                    margin-bottom: .375rem;
                }
                .subtitle{
                    font-size: .9375rem;
                    line-height: 1.125rem;
                }
                .line{
                    width: 2.3125rem;
                    height: .375rem;
                    border-radius: 2.25rem;
                    margin-top: -.32rem;
                }
            }
            .panorama-image{
                margin-top: 3.25rem;
                width: 21.0625rem;
                height: 12.5625rem;
                border: .25rem solid #0496FF;
            }
        }
        .detail-remark{
            .remark-top{
    
            }
            .detail-remark-title{
                .remark-title{
                    height: 17.875rem;
                    padding-top: 3.5625rem;
                    .title{
                        font-size: 2rem;
                        line-height: 2.375rem;
                    }
                    .subtitle{
                        font-size: .9375rem;
                        line-height: 1.125rem;
                    }
                    .line{
                        width: 3.75rem;
                        height: .375rem;
                        border-radius: 1.25rem;
                        margin-top: -.4rem;
                    }
                }
            }
            .detail-remark-content{
                .RemarkComponent{
                    min-height: 50.75rem;
                    .content{
                        width: 22.25rem;
                        padding: 3.3125rem 1.25rem;
                        border-radius: 2.375rem;
                        top: 12rem;
                        .item{
                            font-size: .6875rem;
                            line-height: 1.0625rem;
                            margin-bottom: 1.6875rem;
                            .ant-input{
                                height: 2.5625rem;
                                border-radius: .625rem;
                                font-size: .6875rem;
                            }
                            p{
                                margin-bottom: .5rem;
                            }
                        }
                        .title{
                            font-size: .6875rem;
                        }
                        .name{
                            .ant-input{
                            }
                        }
                        .connect{
                            display: block;
                            .tel{
                                margin-bottom: 1.6875rem;
                            }
                            .ant-input{
                                width: 100%;
                            }
                        }
                        .room{
                            .ant-select{
                                height: 2.5625rem;
                                .ant-select-selector{
                                    border-radius: .625rem;
                                    .ant-select-selection-item{
                                        line-height: 2.5625rem;
                                        font-size: .6875rem;
                                    }
                                }
                                .ant-select-arrow{
                                    right: .6875rem;
                                    width: 1rem;
                                    height: 1rem;
                                    top: 45%;
                                    .anticon{
                                        svg{
                                            font-size: .6875rem;
                                        }
                                    }
                                }
                                &:first-child{
                                    width: 13.125rem;
                                    // margin-right: 1.125rem;
                                }
                                &:last-child{
                                    width: 5.1875rem;
                                }
                            }
                            .select{
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                        .desc{
                            .ant-input{
                                height: 9.75rem !important;
                            }
                        }
                        .btn{
                            .ant-btn{
                                width: 7.375rem;
                                height: 2.5625rem;
                                border-radius: 1.625rem;
                                font-size: .6875rem;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .detail-footer{
        height: 25.375rem;
        padding: 2.75rem 2.125rem;
        .layout{
            display: block;
            width: 100%;
            .footer-img{
                width: 8.9375rem;
                height: 1.4375rem;
            }
            .footer-content{
                .footer-title{
                    font-size: .875rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    margin-top: 1.875rem;
                    margin-bottom: .3125rem;
                }
                .footer-desc{
                    display: block;
                    font-size: .6875rem;
                    line-height: .8125rem;
                    margin-bottom: 2.375rem;
                    .subtitle{
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    .desc{
                        font-size: .6875rem;
                        line-height: .8125rem;
                        text-align: left;
                        .desc-text{
                            margin-bottom: .625rem;
                            color: white;
                            cursor: pointer;
                        }
                        .img-desc{
                            .img-instagram{
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: .375rem;
                            }
                            .wechatCode{
                                .tip-text{
                                    font-size: .6875rem !important;
                                }
                            }
                        }
                    }
                    .descMobile{
                        display: flex;
                    }
                }
                .footer-flex{
                    display: block;
                    .flex-item{
                        margin: 0;
                    }
                }
            }
        }
    }
    .detail-beaver{
        width: 3.25rem;
        height: 2.375rem;
    }
}

@keyframes scroll {
    0% { transform: translateX(0); } /* 初始位置 */
    // 50% { transform: translateX(-50%); }
    100% { transform: translateX(calc(-100% + 25.6rem)); } /* 滚动到最左侧 */
}
