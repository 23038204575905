.ant-layout{
    min-height: 100vh !important;
    background-color: white !important;
    .Header{
        padding: 0 .44rem;
        height: 1.08rem;
        background-color: transparent;
        // position: fixed;
        // top: 0;
        // width: 100%;
        
        .AppHeader{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 14.4rem;
            background-color: white !important;
            margin: 0 auto;
            img{
                width: 2.24rem;
                height: .42rem;
                margin-right: .25rem;
                cursor: pointer;
                // width: 100%;
                // height: 100%;
            }
            .header-left{
                display: flex;
                align-items: center;
            }
            .header-list{
                display: flex;
                .header-li{
                    height: .6rem;
                    margin-left: .75rem;
                    text-align: center;
                    cursor: pointer;
                    >p{
                        line-height: .3rem;
                        font-size: .2rem;
                    }
                    .li-top{
                        font-size: .22rem;
                    }
                    .li-bottom{
                        font-size: .18rem;
                    }
                }
            }
            .language{
                width: 1.17rem;
                height: .53rem;
                .ant-select{
                    width: 100%;
                    height: 100%;
                    .ant-select-selector{
                        font-size: .21rem;
                        height: 100%;
                        padding-left: .27rem;
                        border: none;
                        // background-color: rgb(233, 246, 255);
                        .ant-select-selection-item{
                            line-height: .53rem;
                            // color: #9C9DA9;
                        }
                    }
                    .ant-select-arrow{
                        right: .29rem;
                        .anticon{
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    .IndexHeader{
        background-color: #e4f5fe !important;
        .AppHeader{
            width: 100%;
            background-color: #e4f5fe !important;
            .ant-select{
                .ant-select-selector{
                    background-color: #e4f5fe;

                }
            }
        }
    }
    .HeaderMobile{
        height: 3rem;
        padding: 0;
        position: relative;
        box-sizing: border-box;

        // display: none;
        .AppHeader{
            width: 100%;
            .mobileIcon{
                width: 6rem;
                height: 1.125rem;
                margin-left: 1rem;
            }
            .header-left{
                // img{
                //     display: none;
                // }
                img{
                    width: 11.4375rem;
                    height: 2.125rem;
                }
                flex-direction: column;
                position: absolute;
                background-color: white;
                top: 3rem;
                z-index: 4;
                width: 100%;
                // display: none;
                height: 0;
                transition: height .2s;
                overflow: hidden;
                .header-list{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 4.3125rem;
                    width: 100%;
                    text-align: center;
                    .header-li{
                        height: 2.9375rem;
                        margin-bottom: 2.0625rem;
                        margin-left: 0;
                        .li-top{
                            line-height: 1.375rem;
                            font-size: 1.1875rem;

                        }
                        .li-bottom{
                            font-size: .875rem;
                            line-height: 1.0625rem;
                        }
                    }
                }
            }
            .show-header-left{
                // display: flex;
                height: calc(100vh - 3rem);
            }
            .menu-icon{
                // background-color: blue;
                width: .625rem;
                height: .625rem;
                margin-right: 1rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .language{
                // position: absolute;
                // width: 3rem;
                // height: 2rem;
                // z-index: 4;
                // top: 7rem;
                width: auto;
                height: auto;
                .ant-select{
                    width: 5.125rem;
                    height: 2.625rem;
                    width: 100%;
                    .ant-select-selector{
                        font-size: 1.0625rem;
                        border: none;
                        .ant-select-selection-item{
                            line-height: 2.625rem;
                            color: #000000;
                        }
                    }
                    .ant-select-dropdown{
                        width: 100%;
                    }
                }
                .ant-select-focused .ant-select-selector,
                .ant-select-selector:focus,
                .ant-select-selector:active{
                    border: none !important;
                    box-shadow: none !important;
                }
            }
            .header-language{
                height: 11.625rem;
                .lang-text{
                    height: 2.625rem;
                    line-height: 2.625rem;
                    font-size: 1.0625rem;
                    .anticon{
                        font-size: .875rem;
                        margin-left: .75rem;
                    }
                }
                .lang-list{
                    height: 0;
                    overflow: hidden;
                    font-size: .875rem;
                    transition: height .2s;
                    .lang-li{
                        height: 3rem;
                        line-height: 3rem;
                        background-color: #F9F9F9;
                        color: #9C9DA9;
                        border-bottom: .0625rem solid rgba($color: #000000, $alpha: .06);
                    }
                }
            }
            .header-language-active{
                .lang-list{
                    height: calc(100% - 2.625rem);
                }
            }
        }
    }

    .Footer{
        background-color: #002343;
        height: .78rem;
        padding: 0;
        .AppFooter{
            width: 16rem;
            margin: 0 auto;
            color: rgba($color: #fff, $alpha: .75);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .16rem;
            line-height: .78rem;
            .footer-text{
                .footer-list{
                    display: flex;
                    li{
                        line-height: 1.02rem;
                        margin-right: .47rem;
                    }
                }
            }
            // .connect{
            //     display: flex;
            //     align-items: center;
            //     .icon{
            //         width: .4rem;
            //         height: .4rem;
            //         border-radius: .13rem;
            //         background-color: #E1E1E1;
            //         margin-right: .15rem;
            //         img{
            //             width: 100%;
            //             height: 100%;
            //         }
            //     }
            // }
        }
    }
    .FooterMobile{
        height: 3.4375rem;
        .AppFooter{
            display: block;
            text-align: center;
            line-height: 3.4375rem;
            .statement{
                // font-size: .6875rem;
                // line-height: 3.4375rem;
                // margin-top: 2.5625rem;
                font-size: .6875rem;
            }
        }
    }
}

.page-mask{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    background-color: rgb(101, 199, 249);
    text-align: center;
    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
