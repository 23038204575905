.Index{
    width: 100%;
    .Index-content{
        // width: 14.4rem;
        // margin: 0 auto;
    }
    .Index-banner{
        // height: 11.7rem;
        background-color: #E9F6FF;
        position: relative;
        overflow: hidden;
        img{
            width: 100%;
        }
        .banner-title{
            font-size: 1.3rem;
            line-height: 1.52rem;
            color: #000;
            font-weight: bold;
            width: 11.6rem;
            text-align: center;
            position: absolute;
            top: 3.2rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            letter-spacing: .03rem;
            .symbol{
                margin: 0 .3rem;
            }
            animation: scaleAnimation 3.5s linear 1s 1;
            .banner-title-animation{
                display: inline-block;
                &:first-child{
                    animation-delay: 0s;
                    animation: fontAnimation1 3.5s linear 1s 1;
                }
                &:nth-child(2){
                    animation: fontAnimation2 3.5s linear 1s 1;
                }
                &:nth-child(3){
                    animation: fontAnimation3 3.5s linear 1s 1;
                }
                &:nth-child(4){
                    animation: fontAnimation4 3.5s linear 1s 1;
                }
                &:nth-child(5){
                    animation: fontAnimation5 3.5s linear 1s 1;
                }
                &:nth-child(6){
                    animation: fontAnimation6 3.5s linear 1s 1;
                }
                &:nth-child(7){
                    animation: fontAnimation7 3.5s linear 1s 1;
                }
                &:nth-child(8){
                    animation: fontAnimation8 3.5s linear 1s 1;
                }
                &:nth-child(9){
                    animation: fontAnimation9 3.5s linear 1s 1;
                }
                &:nth-child(10){
                    animation: fontAnimation10 3.5s linear 1s 1;
                }
                &:nth-child(11){
                    animation: fontAnimation11 3.5s linear 1s 1;
                }
                &:nth-child(12){
                    animation: fontAnimation12 3.5s linear 1s 1;
                }
                &:nth-child(13){
                    animation: fontAnimation13 3.5s linear 1s 1;
                }

                &:nth-child(14){
                    animation: fontAnimation14 3.5s linear 1s 1;
                }
            }
        }
        .banner-subtitle{
            color: #000001;
            font-size: .48rem;
            line-height: .56rem;
            position: absolute;
            top: 6.4rem;
            left: 50%;
            font-weight: bold;
            transform: translateX(-50%);
        }
        .banner-line{
            width: 2.96rem;
            height: .15rem;
            border-radius: .19rem;
            background-color: #0496FF;
            opacity: .5;
            position: absolute;
            top: 6.8rem;
            left: 50%;
            transform: translateX(-50%);
        }
        .banner-beaver{
            overflow: hidden;
            width: 100%;
        }
        .left-box{
            position: absolute;
            bottom: -.5rem;
            left: -4rem;
            animation: beaverTranslate1 28s linear 4s infinite;
            width: 100%;
        }
        .animation-beaver-left{
            width: 2.2rem;
            height: 2.2rem;
            overflow: hidden;
            background: url('../images/img/beaver-leftToright.jpg') 0 / cover no-repeat;
            animation: beaverAnimation 1s steps(8) infinite backwards, reversalAnimation 28s linear 4s infinite;

        }
    }
    .Index-border{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .Index-beaver{
        width: 2.65rem;
        height: 2.2rem;
        position: fixed;
        right: .75rem;
        bottom: .47rem;
        z-index: 99;
        cursor: pointer;
        display: none;
        .img-box{
            position: relative;
            height: 100%;
        }
        .img-bottom{
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .img-top{
            width: 1.3rem;
            height: .62rem;
            position: absolute;
            top: .5rem;
            animation: beaverTextAnimation 5.5s linear infinite;
        }
    }
    .show-beaver{
        display: block;
    }
    .Index-thing{
        padding: 1.5rem 0 1rem;
        background-color: rgba($color: #fff, $alpha: .7);
        background-image: url('../images/img/thing-bg.png'), ;
        background-size: 1.87rem 7.21rem;
        background-repeat: no-repeat;
        background-position: left top;
        background-origin: border-box;
        min-height: 100vh;
        // width: 50%;
        // margin: 0 auto;
        .thing-top{
            text-align: center;
            font-weight: 600;
            .title{
                font-size: .6rem;
                line-height: .8rem;
                letter-spacing: .0067rem;
                color: #000;
            }
            .desc{
                font-size: .26rem;
                color: #0496FF;
            }
            .line{
                width: .63rem;
                height: .1rem;
                border-radius: .05rem;
                background-color: #69DDFF;
                margin: -0.1rem auto;
            }
        }
        .thing-bottom{
            padding-bottom: 1.15rem;
            // .topBg{
            //     height: 1.38rem;
            //     background: linear-gradient(180deg, #ECF7FF 0%, rgba(236,247,255,0) 100%);
            // }
            margin-bottom: 1.86rem;
            position: relative;
            
            .flex-box{
                display: flex;
                justify-content: space-between;
                padding: 0 1.6rem;
                .link-mask{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    pointer-events:auto;
                    z-index: 99;
                    display: none;
                }
                .showMask{
                    display: block;
                }
            }
            .thing-content{
                width: 11rem;
                margin-top: 3rem;
                .content-li{
                    display: flex; 
                    line-height: .35rem;
                    padding: .27rem 0;
                    border: .01rem solid #EEEEEE;
                    border-right: none;
                    border-left: none;
                    color: #000000;
                    .content-subtitle{
                        width: 3rem;
                    }
                    &:nth-of-type(1){
                        border-bottom: none;
                    }
                    &:nth-of-type(2){
                        border-bottom: none;
                    }
                    .content-subtitle{
                        font-size: .3rem;
                    }
                }
                .content-title{
                    font-size: .4rem;
                    line-height: .45rem;
                    font-weight: 600;
                    // margin-bottom: .6rem;
                    padding-left: .23rem;
                    position: relative;
                    height: .6rem;
                    img{
                        width: .54rem;
                        height: .52rem;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .li-title{
                        position: absolute;
                        left: .25rem;
                        top: 0;
                        bottom: 0;
                        width: 11rem;
                        display: inline-block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                        color: #000;
                    }
                }
                .desc{
                    font-size: .3rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    width: 8rem;
                    line-height: .35rem;
                }
                .tag{
                    display: flex;
                    color: white;
                    flex-wrap: wrap;
                    p{
                        padding: .04rem .14rem;
                        margin-right: .15rem;
                        border-radius: .13rem;
                        margin-bottom: .04rem;
                        font-size: .24rem;
                        // margin: .07rem .15rem .07rem 0;
                        &:nth-child(1){
                            background-color: #A0A49F;
                        }
                        &:nth-child(2){
                            background-color: #BC9A87;
                        }
                        &:nth-child(3){
                            background: #87B6BC;
                        }
                        &:nth-child(4){
                            background: #AB87BC;
                        }
                        &:nth-child(5){
                            background-color: #BC8787;
                        }
                        &:nth-child(6){
                            background-color: #87BC8C;
                        }
                        &:nth-child(7){
                            background-color: #8796BC;
                        }
                    }
                }
            }
            .thing-img{
                width: 10rem;
                height: 9.8rem;
                position: relative;
                .mask{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                    text-align: center;
                    color: white;
                    background-color: rgba($color: #000000, $alpha: .3);
                    p{
                        font-size: .78rem;
                        line-height: 9.8rem;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-mask-image: url('../images/img/title-bg-large1.png');
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                }
            }
            &:nth-of-type(1n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large1.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large1.png');
                    }
                }
            }
            &:nth-of-type(2n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large2.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large2.png');
                    }
                }
            }
            &:nth-of-type(3n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large3.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large3.png');
                    }
                }
            }
            &:nth-of-type(4n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large4.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large4.png');
                    }
                }
            }
            &:nth-of-type(5n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large5.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large5.png');
                    }
                }
            }
            &:nth-of-type(6n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large6.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large6.png');
                    }
                }
            }
            &:nth-of-type(7n){
                .thing-img{
                    img{
                        -webkit-mask-image: url('../images/img/title-bg-large7.png');
                    }
                    .mask{
                        -webkit-mask-image: url('../images/img/title-bg-large7.png');
                    }
                }
            }
        }
        .first-thing{
            // background-image: url('../images/img/thing-bg2.png');
            // background-position: right bottom;
            // background-repeat: no-repeat;
            // background-size: 3.8rem 2.55rem;
            padding-top: .62rem;
            .thing-img{
                width: 10rem;
                height: 10rem;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-mask-image: url('../images/img/title-bg-large1.png');
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                }
            }
        }
        .second-thing{
            position: relative;
            padding-top: 0;
            .thing-bg{
                position: absolute;
                right: 0;
                bottom: 0;
                width: 11.6rem;
                height: 3.9rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .thing-img{
                width: 10rem;
                height: 10rem;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-mask-image: url('../images/img/title-bg-large2.png');
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                }
                
            }
        }
        .third-thing{
            .thing-img{
                width: 10rem;
                height: 10rem;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    -webkit-mask-image: url('../images/img/title-bg-large3.png');
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                }
                .mask{
                    
                    
                    
                }
            }
        }
    }
    .Index-remark{
        .remark-top{
            display: flex;
            .bg-li{
                width: .5rem;
                height: 6.44rem;
                background-color: #E0F2FF;
                margin-right: .5rem;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .remark-img{
            width: 100%;
            height: 2.75rem;
            img{
                width: 100%;
                height: 100%;
            }
            position: relative;
            z-index: 3;
        }
        .remark-wave{
            width: 100%;
            img{
                width: 100%;
            }
            margin-top: -1.8rem;
            z-index: 2;
            position: relative;
        }
        .remark-bottom{
            margin-top: -5.5rem;
            .remark-title{
                text-align: center;
                position: relative;
                z-index: 1;
                margin-bottom: .8rem;
                .title{
                    font-size: .9rem;
                    line-height: 1.26rem;
                    font-weight: bold;
                    color: #0496FF;
                    margin-bottom: .11rem;
                }
                .subtitle{
                    font-size: .28rem;
                    color: #000000;
                }
                .line{
                    width: 3.04rem;
                    height: .32rem;
                    border-radius: .16rem;
                    background-color: #84CCFF;
                    margin: 0 auto;
                    margin-top: -0.24rem;
                    
                }
            }
            .remark-content{
                color: #00A2FC;
                padding-bottom: 1.5rem;
                padding-top: .83rem;
                background-color: #84CCFF;
                margin-top: -0.3rem;
                position: relative;
                z-index: 4;
                .content{
                    margin: 0 auto;
                    width: 14.4rem;
                    background-color: white;
                    padding: 1.03rem 0.96rem 1.11rem;
                    border-radius: 1.2rem;
                    .item{
                        margin-bottom: .5rem;
                        font-size: .18rem;
                        font-weight: bold;
                        .ant-input{
                            background-color: #F3FBFE;
                            border: none;
                            font-size: .18rem;
                            color: #09A5FC;
                        }
                        p{
                            margin-bottom: .22rem;
                        }
                        .textArea{
                            border-radius: .2rem;
                        }
                    }
                    .title{
                        font-size: .22rem;
                        line-height: .25rem;
                        font-weight: 400;
                    }
                    .name{
                        >p{
                            margin-bottom: .22rem;
                        }
                    }
                    .name,.connect{
                        .ant-input{
                            height: .45rem;
                            border-radius: .16rem;
                            padding-left: .18rem;
                        }
                    }
                    .connect{
                        display: flex;
                        justify-content: space-between;
                        .ant-input{
                            width: 6rem;
                        }
                    }
                    .room{
                        .select{
                            display: flex;
                        }
                        .ant-select{
                            height: .78rem;
                            margin-right: .48rem;
                            width: 6rem;
                            &:last-child{
                                width: 3.6rem;
                            }
                            .ant-select-selector{
                                border: .03rem solid white;
                                background-color: #F3FBFE;
                                color: #09A5FC;
                                font-size: .18rem;
                                height: 100%;
                                border-radius: .16rem;
                                padding-left: .18rem;
                                .ant-select-selection-item{
                                    line-height: .78rem;
                                }
                            }
                            .ant-select-arrow{
                                right: .4rem;
                                top: 40%;
                                width: .3rem;
                                height: .3rem;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                                .anticon{
                                    color: #242331;
                                    svg{
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                    }
                    .desc{
                        .ant-input{
                            min-height: 1.5rem;
                            padding-left: .18rem;
                        }
                    }
                    .btn{
                        .ant-btn{
                            width: 2.28rem;
                            height: .8rem;
                            border-radius: .5rem;
                            font-size: .20rem;
                            background-color: #329DFF;
                            color: white;
                        }
                    }
                }
            }
        }
    }
    .Index-footer{
        background-color: #329DFF;
        height: 5rem;
        padding-top: 1.5rem;
        .layout{
            width: 16rem;
            margin: 0 auto ;
            display: flex;
        }
        .footer-img{
            width: 2.09rem;
            height: .38rem;
            margin-right: 1.34rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .footer-content{
            color: white;
            font-size: .16rem;
            width: calc(100% - 4.09rem);
            .footer-title{
                margin-bottom: .07rem;
                font-weight: 600;
            }
            .footer-desc{
                display: flex;
                .subtitle{
                    margin-right: .21rem;
                }
                .desc{
                    line-height: .22rem;
                    font-size: .14rem;
                    .desc-text{
                        margin-bottom: .1rem;
                    }
                    .desc-link{
                        display: block;
                        color: white;
                    }
                    .img-desc{
                        display: flex;
                        align-items: center;
                        .img-instagram{
                            margin-right: .1rem;
                            width: .18rem;
                            height: .18rem;
                        }
                        .redBook{
                            display: flex;
                            align-items: center;
                            color: white;
                        }
                    }
                }
            }
            .footer-flex{
                display: flex;
                .flex-item{
                    // flex: 1;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.IndexMobile{
    .Index-content{
        width: 100%;
    }
    .Index-banner{
        height: 18.625rem;
        .banner-title{
            font-size: 2.0625rem;
            line-height: 2.4375rem;
            width: 18.125rem;
            top: 3.75rem;
        }
        .banner-subtitle{
            font-size: .8125rem;
            top: 8.75rem;
        }
        .banner-line{
            width: 4.9375rem;
            height: .25rem;
            border-radius: .3125rem;
            top: 9.0625rem;
        }
        img{
            height: 100%;
            object-fit: cover;
        }
    }
    .Index-border{
        margin-top: -.5rem;
    }
    .Index-beaver{
        width: 3.25rem;
        height: 2.375rem;
        right: .625rem;
    }
    .Index-thing{
        background-image: none;
        padding-top: 1.375rem;
        .thing-top{
            margin-bottom: 4.875rem;
            .title{
                font-size: 2rem;
                line-height: 2.375rem;
                font-weight: 700;
            }
            .desc{
                font-size: .9375rem;
                line-height: 1.125rem;
                position: relative;
                z-index: 2;
            }
            .line{
                width: 1.875rem;
                height: .3125rem;
                border-radius: 1.75rem;
                margin-top: -.5rem;
            }
        }
        .thing-bottom{
            padding-bottom: 7.1875rem;
            .flex-box{
                display: block;
                .showMask{
                    display: block;
                }
                .thing-content{
                    margin: 0;
                    width: 21.0625rem;
                    .content-title{
                        height: auto;
                        img{
                            width: 1.875rem;
                            height: 1.875rem;
                        }
                        line-height: 1.375rem;
                        padding-bottom: .6875rem;
                        padding-left: .875rem;
                        .li-title{
                            font-size: 1.1875rem;
                            position: relative;
                            left: auto;
                            top: .3rem;
                            width: 100%;
                            font-weight: 700;
                        }
                    }
                    .content-li{
                        padding:  1.25rem 0;
                        .content-subtitle{
                            width: 5.375rem;
                            font-size: .8125rem;
                            line-height: 1rem;
                            margin-right: 2.375rem;
                        }
                        .tag{
                            width: 13.125rem;
                            flex-wrap: wrap;
                            p{
                                padding: .3125rem .625rem;
                                // line-height: .875rem;
                                font-size: .75rem;
                                border-radius: .75rem;
                                margin-right: .5625rem;
                                margin-bottom: .5625rem;
                            }
                        }
                        .desc{
                            width: 13.125rem;
                            font-size: .75rem;
                            line-height: 1rem;
                        }
                    }
                }
                .thing-img{
                    width: 21.0625rem;
                    height: 20.4375rem;
                    margin-top: 2.375rem;
                    .mask{
                        p{
                            font-size: 2rem;
                            line-height: 20.4375rem;
                        }
                    }
                }
            }
        }
    }
    .Index-remark{
        .remark-top{
            .bg-li{
                width: .4375rem;
                height: 10.375rem;
                margin-right: .4688rem;
            }
        }
        .remark-bottom{
            margin-top: -6.9rem;
            .remark-title{
                .title{
                    font-size: 2rem;
                    line-height: 2.375rem;
                }
                .subtitle{
                    font-size: .9375rem;
                    line-height: 1.125rem;
                }
                .line{
                    width: 3.75rem;
                    height: .375rem;
                    border-radius: 1.25rem;
                    margin-top: -.4rem;
                }
            }
            .remark-content{
                .RemarkComponent{
                    .content{
                        width: 22.25rem;
                        padding: 3.3125rem 1.25rem;
                        border-radius: 2.375rem;
                        .item{
                            font-size: .6875rem;
                            line-height: 1.0625rem;
                            margin-bottom: 1.6875rem;
                            .ant-input{
                                height: 2.5625rem;
                                border-radius: .625rem;
                                font-size: .6875rem;
                            }
                            p{
                                margin-bottom: .5rem;
                            }
                        }
                        .title{
                            font-size: .6875rem;
                        }
                        .name{
                            .ant-input{
                            }
                        }
                        .connect{
                            display: block;
                            .tel{
                                margin-bottom: 1.6875rem;
                            }
                            .ant-input{
                                width: 100%;
                            }
                        }
                        .room{
                            .ant-select{
                                height: 2.5625rem;
                                .ant-select-selector{
                                    border-radius: .625rem;
                                    .ant-select-selection-item{
                                        line-height: 2.5625rem;
                                        font-size: .6875rem;
                                    }
                                }
                                .ant-select-arrow{
                                    right: .6875rem;
                                    width: 1rem;
                                    height: 1rem;
                                    top: 45%;
                                    .anticon{
                                        svg{
                                            font-size: .6875rem;
                                        }
                                    }
                                }
                                &:first-child{
                                    width: 13.125rem;
                                    margin-right: 1.125rem;
                                }
                                &:last-child{
                                    width: 5.1875rem;
                                }
                            }
                        }
                        .desc{
                            .ant-input{
                                height: 9.75rem !important;
                            }
                        }
                        .btn{
                            .ant-btn{
                                width: 7.375rem;
                                height: 2.5625rem;
                                border-radius: 1.625rem;
                                font-size: .6875rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .Index-footer{
        height: 25.375rem;
        padding: 2.75rem 2.125rem;
        .layout{
            display: block;
            width: 100%;
            .footer-img{
                width: 8.9375rem;
                height: 1.4375rem;
            }
            .footer-content{
                .footer-title{
                    font-size: .875rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    margin-top: 1.875rem;
                    margin-bottom: .3125rem;
                }
                .footer-desc{
                    display: block;
                    font-size: .6875rem;
                    line-height: .8125rem;
                    margin-bottom: 2.375rem;
                    .subtitle{
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    .desc{
                        font-size: .6875rem;
                        line-height: .8125rem;
                        text-align: left;
                        .desc-text{
                            margin-bottom: .625rem;
                            cursor: pointer;
                            color: white;
                        }
                        .img-desc{
                            .img-instagram{
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: .375rem;
                            }
                            .wechatCode{
                                .tip-text{
                                    font-size: .6875rem !important;
                                }
                            }
                        }
                    }
                    .descMobile{
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
            .footer-flex{
                display: block;
                .flex-item{
                    margin: 0;
                }
            }
        }
    }
}

@keyframes fontAnimation1 {
    0% {
        transform: scale(1);
    }
    1.8% {
        transform: scale(1.4);
    }
    3.6%{
        transform: scale(1);
    }
}

@keyframes fontAnimation2 {
    3.6% {
        transform: scale(1);
    }
    5.4% {
        transform: scale(1.4);
    }
    7.2% {
        transform: scale(1);
    }
}

@keyframes fontAnimation3 {
    7.2% {
        transform: scale(1);
    }
    9%{
        transform: scale(1.4);
    }
    10.8%{
        transform: scale(1);
    }
}

@keyframes fontAnimation4 {
    10.8%{
        transform: scale(1);
    }
    12.6%{
        transform: scale(1.4);
    }
    14.4%{
        transform: scale(1);
    }
}

@keyframes fontAnimation5 {
    14.4%{
        transform: scale(1);
    }
    16.2%{
        transform: scale(1.4);
    }
    18%{
        transform: scale(1);
    }
}

@keyframes fontAnimation6 {
    18%{
        transform: scale(1);
    }
    19.8%{
        transform: scale(1.4);
    }
    21.6%{
        transform: scale(1);
    }
}

@keyframes fontAnimation7 {
    21.6%{
        transform: scale(1);
    }
    23.4%{
        transform: scale(1.4);
    }
    25.2%{
        transform: scale(1);
    }
}

@keyframes fontAnimation8 {
    25.2%{
        transform: scale(1);
    }
    27%{
        transform: scale(1.4);
    }
    28.8%{
        transform: scale(1);
    }
}

@keyframes fontAnimation9 {
    28.8%{
        transform: scale(1);
    }
    30.6%{
        transform: scale(1.4);
    }
    32.4%{
        transform: scale(1);
    }
}

@keyframes fontAnimation10 {
    32.4%{
        transform: scale(1);
    }
    34.2%{
        transform: scale(1.4);
    }
    36%{
        transform: scale(1);
    }
}

@keyframes fontAnimation11 {
    36%{
        transform: scale(1);
    }
    37.8%{
        transform: scale(1.4);
    }
    39.6%{
        transform: scale(1);
    }
}

@keyframes fontAnimation12 {
    39.6%{
        transform: scale(1);
    }
    41.4%{
        transform: scale(1.4);
    }
    43.2%{
        transform: scale(1);
    }
}

@keyframes fontAnimation13 {
    43.2%{
        transform: scale(1);
    }
    45%{
        transform: scale(1.4);
    }
    46.8%{
        transform: scale(1);
    }
}

@keyframes fontAnimation14 {
    46.8%{
        transform: scale(1);
    }
    48.6%{
        transform: scale(1.4);
    }
    50%{
        transform: scale(1);
    }
}

@keyframes scaleAnimation {
    50%, 71.6%, 60.8% {
      transform: scale(1);
    }
    55.4%, 66.2% {
      transform: scale(1.2);
    }
  }

@keyframes beaverAnimation {
    to {
        background-position: -17.6rem;
    }
}

@keyframes beaverTranslate1 {
    
    0%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(120%);
    }
    
    100%{
        transform: translateX(0);
    }
}


@keyframes beaverTextAnimation {
    0%{
        transform: scale(0.01);
    }
    10%{
        transform: scale(1);
    }
    // 100%{
    //     transform: scale(1);
    // }
}

@keyframes reversalAnimation {
    0%{

    }
    49%{
        transform: scaleX(1);
    }
    51%{
        transform: scaleX(-1);
    }
    99%{
        transform: scaleX(-1);
    }
    100%{
        // transform: scaleX(1);
    }
}

