.Statement{
    background-color: #F5F6F9;
    min-height: calc(100vh);
    .content{
        width: 11.4rem;
        padding-top: .28rem;
        margin: 0 auto;
        padding-bottom: 1.5rem;
    }
    .statement-item{
        .statement-title{
            // height: .8rem;
            line-height: .7rem;
            background-color: white;
            padding-left: .6rem;
            border-top: .1rem solid #329DFF;
            .title-text{
                font-size: .27rem;
                color: #000;
            }
        }
        .statement-content{
            margin-top: .1rem;
            padding: .43rem .6rem .77rem .46rem;
            background-color: white;
            border-radius: .11rem;
            .content-text{
                font-size: .14rem;
                color: #000;
                line-height: .16rem;
                margin-bottom: .1rem;
                letter-spacing: .01rem;
                padding-left: .14rem;
            }
            .paragraph{
                margin-bottom: .5rem;
            }
            .emphasize{
                border-left: .04rem solid #329DFF;
                background-color: rgba($color: #329DFF, $alpha: .1);
                line-height: .25rem;
                margin-top: .4rem;
            }
            .content-table{
                width: 8.41rem;
                margin: .5rem auto;
                .table-row{
                    display: flex;
                    border: 1px solid #000;
                    border-bottom: none;
                    &:last-child{
                        border-bottom: 1px solid #000;
                    }
                    .row-item{
                        flex: 1;
                        padding: .14rem .2rem .14rem .2rem ;
                        &:nth-child(1) {
                            border-right: 1px solid #000;
                        }
                        .item-text{
                            font-size: .14rem;
                            line-height: .16rem;
                            color: #000;
                            letter-spacing: .01rem;
                        }
                    }
                }
            }
            .strong{
                color: #329DFF;
            }
            .aggravate{
                font-weight: bold;
                margin: .1rem 0;
            }
            .footer-text{
                margin-top: .7rem;
                padding-left: .14rem;
                font-size: .14rem;
                color: #000;
                line-height: .16rem;
            }
        }
    }
    .detail-footer{
        background-color: #329DFF;
        padding-top: .9rem;
        padding-bottom: 1.13rem;
        .layout{
            width: 14.4rem;
            margin: 0 auto ;
            display: flex;
        }
        .footer-img{
            width: 2.09rem;
            height: .38rem;
            margin-right: 1.34rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .footer-content{
            color: white;
            width: calc(100% - 4.09rem);
            font-size: .16rem;
            .footer-title{
                margin-bottom: .07rem;
                font-weight: 600;
            }
            .footer-desc{
                display: flex;
                .subtitle{
                    margin-right: .21rem;
                }
                .desc{
                    line-height: .22rem;
                    font-size: .14rem;
                    .desc-text{
                        margin-bottom: .1rem;
                    }
                    .desc-link{
                        display: block;
                        color: white;
                    }
                    .img-desc{
                        display: flex;
                        align-items: center;
                        .img-instagram{
                            margin-right: .1rem;
                            width: .18rem;
                            height: .18rem;
                        }
                        .redBook{
                            display: flex;
                            align-items: center;
                            color: white;
                        }
                    }
                }
            }
            .footer-flex{
                display: flex;
                .flex-item{
                    // flex: 1;
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

.StatementMobile{
    .content{
        width: 100%;
        padding: 1.125rem 0 4.75rem;
        .statement-item{
            .statement-title{
                height: 3.3125rem;
                line-height: 2.625rem;
                border-top: .5rem solid #329DFF;
                padding: 0 1.1875rem;
                .title-text{
                    font-size: 1.1875rem;
                }
            }
            .statement-content{
                padding: 2rem 1.1875rem 3.5625rem;
                border-radius: .5rem;
                margin-top: .5rem;
                .content-text{
                    font-size: .6875rem;
                    letter-spacing: 1px;
                    line-height: .8125rem;
                    margin-bottom: .5rem;
                }
                .paragraph{
                    margin-bottom: 1.5rem;
                }
                .emphasize{
                    border-left: 1px solid #329DFF;
                    height: 1.1875rem;
                    line-height: 1.1875rem;
                }
                .content-table{
                    width: 100%;
                    .table-row{
                        .row-item{
                            padding: .75rem .375rem;
                            .item-text{
                                font-size: .6875rem;
                                line-height: .8125rem;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
                .footer-text{
                    font-size: .6875rem;
                    letter-spacing: 1px;
                    line-height: .8125rem;
                    margin-top: 3.75rem;
                }
            }
        }
    }
    .detail-footer{
        height: 25.375rem;
        padding: 2.75rem 2.125rem;
        .layout{
            display: block;
            width: 100%;
            .footer-img{
                width: 8.9375rem;
                height: 1.4375rem;
            }
            .footer-content{
                .footer-title{
                    font-size: .875rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    margin-top: 1.875rem;
                    margin-bottom: .3125rem;
                }
                .footer-desc{
                    display: block;
                    font-size: .6875rem;
                    line-height: .8125rem;
                    margin-bottom: 2.375rem;
                    .subtitle{
                        font-weight: 500;
                        margin: .5rem 0;
                    }
                    .desc{
                        font-size: .6875rem;
                        line-height: .8125rem;
                        text-align: left;
                        .desc-text{
                            margin-bottom: .625rem;
                            color: white;
                            cursor: pointer;
                        }
                        .img-desc{
                            .img-instagram{
                                width: 1.4375rem;
                                height: 1.4375rem;
                                margin-right: .375rem;
                            }
                            .wechatCode{
                                .tip-text{
                                    font-size: .6875rem !important;
                                }
                            }
                        }
                    }
                    .descMobile{
                        display: flex;
                    }
                }
                .footer-flex{
                    display: block;
                    .flex-item{
                        margin: 0;
                    }
                }
            }
        }
    }
}